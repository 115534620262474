import React from 'react'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core'
import { loadTranslation } from '../../translation'

import './style.css'

export default function SelectorRegion (props) {
    return (
        <div className="selector-region">
                <Typography className='selector-label' variant='subtitle1'>{loadTranslation((translate) => translate({id: 'REGION'}))}</Typography>
                <ButtonGroup size="small">
                    {
                        props.regions.map((region, index) => (
                        <Button key={index} 
                                color={props.value === region? 'primary':'default'} 
                                variant={props.value === region? 'contained':'outlined'}
                                onClick={() => { props.onChange(region) }}>
                                    {region}
                        </Button>))
                    }
                </ButtonGroup>
        </div>
    )
}