import React from 'react'
import Image from '../Image'
import Typography from '@material-ui/core/Typography'

import './style.css'

export default function ImageInfoBox(props) {
    return (
        <div className='image-info-box'>
            <a href={`${props.url?? ''}`} target="_blank">
            <div className={`image${props.cover? ' cover':''}`}>
                <Image src={props.src} />
            </div>
            <div className='label'><Typography variant='subtitle1'>{props.label}</Typography></div>
            <div className='footer'>
                <div className='item-title'><Typography variant='subtitle2'>{props.title}</Typography></div>
                <div className='item-description'><Typography variant='body2'>{props.description}</Typography></div>
            </div>
            </a>
        </div>
    )
} 