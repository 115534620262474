const modelData = {
    'NQI_GTS': {
        name: 'NQi GTS',
        models: [
            {
                model: 'NQi GTS Pro',
                image_key: 'nqi_gts_pro',
                region: 'EU',
                speed: 70,
                motor: '3000',
                battery: {
                    voltage: 60,
                    amp_hour: 35,
                    count: 2,
                    charge_time: {
                        value: '4 - 7',
                        measure: 'MEASURE_HOUR'
                    }
                },
                dashboard: 'COLOR_SCREEN',
                range: 100,
                price: 2499000
            },
            {
                model: 'NQi GTS Sport',
                image_key: 'nqi_gts_sport',
                region: 'EU',
                speed: 70,
                motor: '3000',
                battery: {
                    voltage: 60,
                    amp_hour: 26,
                    count: 2,
                    charge_time: {
                        value: '3.5 - 6',
                        measure: 'MEASURE_HOUR'
                    }
                },
                dashboard: 'COLOR_SCREEN',
                range: 75,
                price: 1666000
            }
        ],
    },
    'NQI_GT': {
        name: 'NQi GT',
        models: [
            {
                model: 'NQi GT Pro',
                image_key: 'nqi_gt_pro_sport',
                region: 'EU',
                speed: 70,
                motor: '3000',
                battery: {
                    voltage: 60,
                    amp_hour: 35,
                    count: 2
                },
                dashboard: 'COLOR_SCREEN',
                range: 100,
                price: 2450000
            },
            {
                model: 'NQi GT Sport',
                image_key: 'nqi_gt_pro_sport',
                region: 'EU',
                speed: 70,
                motor: '3000',
                battery: {
                    voltage: 60,
                    amp_hour: 26,
                    count: 2
                },
                dashboard: 'COLOR_SCREEN',
                range: 75,
                price: 1617000
            }
        ],
    },
    'UQI_GT': {
        name: 'UQi GT',
        models: [
            {
                model: 'UQi GT Pro',
                image_key: 'uqi_gt_pro_sport',
                region: 'EU',
                speed: 35,
                motor: '1200',
                battery: {
                    voltage: 48,
                    amp_hour: 42,
                    count: 1,
                    charge_time: {
                        value: '9',
                        measure: 'MEASURE_HOUR'
                    }
                },
                dashboard: 'B_AND_W_SINGLE_BATTERY_SCREEN',
                range: 70,
                price: 1127000
            },
            {
                model: 'UQi GT Sport',
                image_key: 'uqi_gt_pro_sport',
                region: 'EU',
                speed: 35,
                motor: 'NIU 1200',
                battery: {
                    voltage: 48,
                    amp_hour: 31,
                    count: 1,
                    charge_time: {
                        value: '7',
                        measure: 'MEASURE_HOUR'
                    }
                },
                dashboard: 'B_AND_W_SINGLE_BATTERY_SCREEN',
                range: 50,
                price: 833000
            }
        ],
    },
    'NQI': {
        name: 'NQi',
        models: [
            {
                model: 'NQi Sport',
                image_key: 'nqi_sport_lite',
                region: 'SEA',
                speed: 55,
                motor: '1500',
                battery: {
                    voltage: 60,
                    amp_hour: 32,
                    count: 1
                },
                dashboard: 'B_AND_W_SINGLE_BATTERY_SCREEN',
                range: 50,
                price: 1127000
            },
            {
                model: 'NQi Lite',
                image_key: 'nqi_sport_lite',
                region: 'EU',
                speed: 45,
                motor: '1200',
                battery: {
                    voltage: 60,
                    amp_hour: 26,
                    count: 1
                },
                dashboard: 'B_AND_W_SINGLE_BATTERY_SCREEN',
                range: 40,
                price: 931000
            }
        ],
    },
    'CARGO': {
        name: 'Cargo',
        models: [
            {
                model: 'NQi Cargo',
                image_key: 'n1s_cargo',
                region: 'EU',
                speed: 45,
                motor: '1500',
                battery: {
                    voltage: 60,
                    amp_hour: 32,
                    count: 2,
                    charge_time: {
                        value: '4 - 7',
                        measure: 'MEASURE_HOUR'
                    }
                },
                dashboard: 'B_AND_W_DUAL_BATTERY_SCREEN',
                range: 100,
                price: 1960000
            },
            {
                model: 'NQi GT Pro Cargo',
                image_key: 'n1s_cargo',
                region: 'EU',
                speed: 70,
                motor: '3000',
                battery: {
                    voltage: 60,
                    amp_hour: 35,
                    count: 2,
                    charge_time: {
                        value: '4-7',
                        measure: 'MEASURE_HOUR'
                    }
                },
                dashboard: 'COLOR_SCREEN',
                range: 105,
                price: 3430000
            },
            {
                model: 'NQi GT Sport Cargo',
                image_key: 'n1s_cargo',
                region: 'EU',
                speed: 70,
                motor: '3000',
                battery: {
                    voltage: 60,
                    amp_hour: 26,
                    count: 2,
                    charge_time: {
                        value: '3.5 - 6',
                        measure: 'MEASURE_HOUR'
                    }
                },
                dashboard: 'COLOR_SCREEN',
                range: 80,
                price: 2450000
            }
        ],
    },
    'MQI_PLUS': {
        name: 'MQi+',
        models: [
            {
                model: 'MQi+ Sport',
                image_key: 'mqi_plus_sport_lite',
                region: 'EU',
                speed: 45,
                motor: '1200',
                battery: {
                    voltage: 48,
                    amp_hour: 42,
                    count: 1
                },
                dashboard: 'M_SERIES_B_AND_W_SCREEN',
                range: 70,
                price: 1127000
            },
            {
                model: 'MQi+ Sport',
                image_key: 'mqi_plus_sport_lite',
                region: 'SEA',
                speed: 45,
                motor: '1200',
                battery: {
                    voltage: 48,
                    amp_hour: 31,
                    count: 1
                },
                dashboard: 'M_SERIES_B_AND_W_SCREEN',
                range: 60,
                price: 882000
            },
            {
                model: 'MQi+ Lite',
                image_key: 'mqi_plus_sport_lite',
                region: 'EU',
                speed: 45,
                motor: '800',
                battery: {
                    voltage: 48,
                    amp_hour: 31,
                    count: 1
                },
                dashboard: 'M_SERIES_B_AND_W_SCREEN',
                range: 50,
                price: 833000
            },
        ],
    },
    'UQI': {
        name: 'UQi',
        models: [
            {
                model: 'UQi Pro',
                image_key: 'uqi_pro_sport',
                region: 'EU',
                speed: 40,
                motor: '800',
                battery: {
                    voltage: 48,
                    amp_hour: 21,
                    count: 1
                },
                dashboard: 'U_SERIES_B_AND_W_SCREEN',
                range: 30,
                price: 833000
            },
            {
                model: 'UQi Sport',
                image_key: 'uqi_pro_sport',
                region: 'SEA',
                speed: 32,
                motor: 'NIU 500',
                battery: {
                    voltage: 48,
                    amp_hour: 16,
                    count: 1
                },
                dashboard: 'U_SERIES_B_AND_W_SCREEN',
                range: 25,
                price: 637000
            }
        ],
    },
    'GLOVE': {
        name: 'G (Glove)',
        models: [
            {
                model: 'G 03',
                image_key: 'uqi_pro_sport',
                region: 'SEA',
                speed: 40,
                motor: 'JYX 600',
                battery: {
                    voltage: 48,
                    amp_hour: 21,
                    count: 1,
                    charge_time: {
                        value: '6.5',
                        measure: 'MEASURE_HOUR'
                    }
                },
                dashboard: 'G_SERIES_B_AND_W_SCREEN',
                range: 55,
                price: 637000
            },
            {
                model: 'G 00',
                image_key: 'uqi_pro_sport',
                region: 'SEA',
                speed: 25,
                motor: 'JYX 400',
                battery: {
                    voltage: 48,
                    amp_hour: 16,
                    count: 1,
                    charge_time: {
                        value: '7',
                        measure: 'MEASURE_HOUR'
                    }
                },
                dashboard: 'G_SERIES_B_AND_W_SCREEN',
                range: 40,
                price: 392000
            }
        ],
    },
    'EUB': {
        name: 'E bysicles',
        models: [
            {
                model: 'EUB 01 Pro',
                image_key: 'eub_01',
                region: 'SEA',
                speed: 45,
                motor: 'BAFANG',
                battery: {
                    voltage: 48,
                    amp_hour: 21,
                    count: 1,
                    charge_time: {
                        value: '6',
                        measure: 'MEASURE_HOUR'
                    }
                },
                dashboard: 'COLOR_SCREEN',
                range: 75,
            },
            {
                model: 'EUB 01 Sport',
                image_key: 'eub_01',
                region: 'SEA',
                speed: 25,
                motor: 'BAFANG',
                battery: {
                    voltage: 48,
                    amp_hour: 16,
                    count: 1,
                    charge_time: {
                        value: '5',
                        measure: 'MEASURE_HOUR'
                    }
                },
                dashboard: 'COLOR_SCREEN',
                range: 85,
            }
        ],
    },
    'KQI': {
        name: 'Kick scooters',
        models: [
            {
                model: 'KQi Pro',
                image_key: 'kqi_pro',
                region: 'SEA',
                speed: 25,
                battery: {
                    voltage: 48,
                    count: 1,
                    charge_time: {
                        value: '6',
                        measure: 'MEASURE_HOUR'
                    }
                },
                dashboard: 'LED',
                range: 50,
            },
            {
                model: 'KQi Sport',
                image_key: 'kqi_sport',
                region: 'SEA',
                speed: 25,
                battery: {
                    voltage: 48,
                    count: 1,
                    charge_time: {
                        value: '5',
                        measure: 'MEASURE_HOUR'
                    }
                },
                dashboard: 'LED',
                range: 40,
            },
        ],
    },
}

const modelImages = {
    'n1s_cargo': {
        folder: 'n1s_cargo',
        main: '/models/n1s_cargo/white/1.jpg',
        colors: {
            'white': 4,
        }
    },
    'mqi_plus_sport_lite': {
        folder: 'mqi_plus_sport_lite',
        main: '/models/mqi_plus_sport_lite/white/1.jpg',
        colors: {
            'white': 12,
            'red': 10,
            'blue': 12,
            'silver': 11,
            'black-red': 8,
        }
    },
    'eub_01': {
        folder: 'eub_01',
        main: '/models/eub_01/white/1.jpg',
        colors: {
            'white': 3,
        }
    },
    'mqi_gt': {
        folder: 'mqi_gt',
        main: '/models/mqi_gt/white/1.jpg',
        colors: {
            'white': 8,
            'red': 5,
            'blue': 5,
            'black': 9,
            'gray': 10,
            'black-red': 5
        }
    },
    'nqi_gt_pro_sport': {
        folder: 'nqi_gt_pro_sport',
        main: '/models/nqi_gt_pro_sport/white-red/1.jpg',
        colors: {
            'white-red': 6,
            'black-red': 7,
            'black-white': 6
        }
    },
    'nqi_gts_pro': {
        folder: 'nqi_gts_pro',
        main: '/models/nqi_gts_pro/white-red/1.jpg',
        colors: {
            'white-red': 2,
            'black-red': 1,
        }
    },
    'nqi_gts_sport': {
        folder: 'nqi_gts_sport',
        main: '/models/nqi_gts_sport/white-red/1.jpg',
        colors: {
            'white-red': 4,
            'black': 2,
            'black-red': 4,
            'black-white': 2,
        }
    },
    'nqi_pro': {
        folder: 'nqi_pro',
        main: '/models/nqi_pro/white-red/1.jpg',
        colors: {
            'white-red': 1,
            'black-red': 1,
            'black-white': 1,
        }
    },
    'nqi_sport_lite': {
        folder: 'nqi_sport_lite',
        main: '/models/nqi_sport_lite/white/1.jpg',
        colors: {
            'white': 5,
            'red': 5,
            'blue': 5,
            'silver': 7,
            'gray': 7,
            'black': 5,
        }
    },
    'rqi': {
        folder: 'rqi',
        main: '/models/rqi/gray/1.jpg',
        colors: {
            'gray': 2,
        }
    },
    'tqi': {
        folder: 'tqi',
        main: '/models/tqi/gray/1.jpg',
        colors: {
            'gray': 2,
        }
    },
    'uqi_gt_pro_sport': {
        folder: 'uqi_gt_pro_sport',
        main: '/models/uqi_gt_pro_sport/white/1.jpg',
        colors: {
            'white': 4,
            'red': 6,
            'blue': 4,
            'gray': 4,
            'black': 4,
        }
    },
    'uqi_pro_sport': {
        folder: 'uqi_pro_sport',
        main: '/models/uqi_pro_sport/white/1.jpg',
        colors: {
            'white': 5,
            'red': 5,
            'blue': 5,
            'black-red': 4,
        }
    },
    'eub_01': {
        folder: 'eub_01',
        main: '/models/eub_01/white/1.jpg',
        colors: {
            'white': 6,
            'grey': 3,
            'black': 3
        }
    },
    'kqi_pro': {
        folder: 'kick_scooter_pro',
        main: '/models/kick_scooter_pro/rose-gold/2.jpg',
        colors: {
            'rose-gold': 6,
            'black': 2
        }
    },
    'kqi_sport': {
        folder: 'kick_scooter_sport',
        main: '/models/kick_scooter_sport/white/1.jpg',
        colors: {
            'white': 1,
            'black': 1,
            'blue': 1,
            'red': 1,
            'white': 1 
        }
    }
}

export const getModelInfo = (modelName) => {
    var modelInfo = []
    Object.keys(modelData).forEach(serieName => {
        modelData[serieName].models.forEach(model => {
            if(toModelName(model.model) === modelName) {
                model.images = modelImages[model.image_key]
                // delete model.image_key
                modelInfo.push(model)
            }
        })
    })
    return modelInfo
}

const filterUniquesFromModelList = (modelList) => {
    var unique = []
    var modelObjMap = {}
    for( let i = 0; i < modelList.length; i++ ){
        if (!modelObjMap[modelList[i].model]) {
            unique.push(modelList[i])
            modelObjMap[modelList[i].model] = true
        }
    }
    return unique
}

export const getModelsBySeries = (serie) => {
    var names = []
    switch(String(serie).toUpperCase()) {
        case 'U':
            Array.prototype.push.apply(names,modelData['UQI'].models)
            Array.prototype.push.apply(names,modelData['UQI_GT'].models)
        break
        case 'N':
            Array.prototype.push.apply(names,modelData['NQI'].models)
            Array.prototype.push.apply(names,modelData['NQI_GT'].models)
            Array.prototype.push.apply(names,modelData['NQI_GTS'].models)
            Array.prototype.push.apply(names,modelData['CARGO'].models)
        break;
        case 'M':
            Array.prototype.push.apply(names,modelData['MQI_PLUS'].models)
        break;
        case 'EUB':
            Array.prototype.push.apply(names,modelData['EUB'].models)
        break;
        case 'KQI':
            Array.prototype.push.apply(names,modelData['KQI'].models)
        break;
        default:
        names = []
    }
    return filterUniquesFromModelList(names)
} 

export const toModelName = (modelName) => {
    return String(modelName).split(' ').join('_').replace('+', '_PLUS').toUpperCase()
}

export default modelData