import React from 'react'
import Image from '../Image'
import './style.css'

export default class BannerLarge extends React.Component {
    render() {
        return (
            <div className={`banner-large${this.props.className? ' ' +this.props.className:''}`}>
                <div className='img-wrapper'>
                    <Image className='img' alt="banner-img" src={this.props.imgSrc} />
                </div>
                <div className="data-section">
                    <div className="title">{this.props.title}</div>
                    <div className="subtitle">{this.props.subtitle}</div>
                    <div className="description">{this.props.description}</div>
                </div>
            </div>
        )
    }
}