import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Pagination from '../Pagination'
import ImageCard from '../ImageCard'
import Image from '../Image'
import Title from '../Title'
import { getAccessories } from '../../helpers/accessory'
import { loadTranslation } from '../../translation' 

import './style.css'

export default function PageAccessories (props) {
    const BANNER_IMAGE_SRC = '/section/accessories.jpeg'
    const limit = 12
    const page = props.match.params.page? parseInt(props.match.params.page):1
    const allPagesCount = Math.ceil(getAccessories(99999999, 0).length / limit)
    const items = getAccessories(limit, page-1)
    return loadTranslation( translate => (
        <div className='page-accessories content-container'>
            <Title pageTitle={translate({id: `ACCESSORIES`})} siteTitle={`NIU ${translate({id: 'ARMENIA'})}`} />
            {BANNER_IMAGE_SRC? <Image className='img-banner' src={BANNER_IMAGE_SRC} />:false}
            <div className='content-body banner-title-container'>
                <Typography className='banner-title' variant='h4'>{translate({id: `ACCESSORIES`})}</Typography>
            </div>
            <div className='content-body'>
                <Grid container>
                    {
                        items.length? items.map(item => (
                            <Grid key={item.id} xl={3} lg={3} md={4} sm={6} xs={12} item>
                                <ImageCard className='accessory-item' href={`/accessory/${item.id}`} src={item.mainImage} title={translate({id: item.name})} />
                            </Grid>
                        )):<Typography variant='h5'>No accessories found</Typography>
                    }
                </Grid>
                <Pagination className='pagination' count={allPagesCount} page={page} />
            </div>
        </div>
    ))
}