import React from 'react'
import Typography from '@material-ui/core/Typography'
import Image from '../Image'

import './style.css'

export default function ImageSection(props) {
    return (
        <div className={`image-section${props.className? ' ' + props.className:''}`}>
            <div className='section-image-wrapper'>
                <Image className='section-image' src={props.src} alt='section' />
            </div>
            <div className='info-block'>
                <div className='title'><Typography variant="h3" >{props.title}</Typography></div>
                <div className='description'><Typography variant="subtitle1" >{props.description}</Typography></div>
                <div className='footer'>{props.footer}</div>
            </div>
        </div>
    )
}