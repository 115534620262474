import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { enablePageScroll, disablePageScroll } from '../../helpers/control'

import './style.css'

const StyledMenu = (props) => <Menu
                                elevation={0}
                                disableScrollLock={true}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                                }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                                }}
                                {...props}
                                />

const StyledMenuItem = MenuItem

export default function LinkDropdown(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    disablePageScroll()
  };

  const handleClose = () => {
    setAnchorEl(null)
    enablePageScroll()
  };

  const handleChange = (value) => {
    if(props.hasOwnProperty('onChange')) {
        props.onChange(value)
        handleClose()
    }
  }
  
  return (
    <div className={`link-dropdown${props.className? ' ' + props.className:''}`}>
      <Button
        className="link-button"
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="text"
        color="primary"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
      >
        {props.label}
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
            props.items.map((item, index) => (
                <StyledMenuItem key={index} onClick={() => handleChange(item.value)} selected={item.value === props.value? true:false}>
                    {item.name}
                </StyledMenuItem>
            ))
        }
      </StyledMenu>
    </div>
  );
}