import { Contacts } from '@material-ui/icons'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Image from '../Image'
import Title from '../Title'
import SectionComingSoon from '../SectionNewConcepts'
import SectionTestDrive from '../SectionTestDrive'
import contacts from '../../helpers/contact'
import { loadTranslation } from '../../translation'

import './style.css'

export default class PageContacts extends React.Component {

    renderInfoRow(label, link, linkPrefix=false, index=false) {
        return (<Typography key={link} variant='h5' className='contact-info' >{label} {index} <a className='contact-link' target='_blank' href={`${linkPrefix? `${linkPrefix}:`:''}${link}`}>{link}</a></Typography>)
    }
    
    render() {
        return loadTranslation( translate => (
            <div className='page-contacts content-container'>
                <Title pageTitle={translate({id: 'CONTACTS'})} siteTitle={`NIU ${translate({id: 'ARMENIA'})}`} />
                <div className='contact-us-banner'>
                    <Image className='contact-image' src={'/section/contacts.jpg'} />
                    <Typography variant='h3' className='title' >{translate({id: 'CONTACT_US'})}</Typography>
                </div>
                <div className='content-body'>
                    <Box mt={4} />
                    <div className='contacts-box'>
                        {
                            contacts.contactInfo.emails.map((email, index) => this.renderInfoRow(translate({id: 'E_MAIL'}), email, 'mailto', contacts.contactInfo.emails.length > 1? ` ${index+1}`:false))
                        }
                        {
                            contacts.contactInfo.tel.map((tel, index) => this.renderInfoRow(translate({id: 'TEL'}), tel, 'tel', contacts.contactInfo.tel.length > 1? ` ${index+1}`:false))
                        }
                        {
                            contacts.officialGroups.facebook? this.renderInfoRow('Facebook', contacts.officialGroups.facebook):false
                        }
                        {
                            contacts.officialGroups.twitter? this.renderInfoRow('Twitter', contacts.officialGroups.twitter):false
                        }
                        {
                            contacts.officialGroups.instagram? this.renderInfoRow('Instagram', contacts.officialGroups.instagram):false
                        }
                        {
                            contacts.officialGroups.youtube? this.renderInfoRow('YouTube', contacts.officialGroups.youtube):false
                        }
                    </div>
                    <Box mt={10} />
                    <SectionTestDrive />
                </div>
            </div>
        ))
    }
}