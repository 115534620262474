import LOCALES from '../locales'

export default {
    [LOCALES.EN]: {
        U_SERIES: 'U-series',
        U_SERIES_DESCRIPTION: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
        N_SERIES: 'N-series',
        N_SERIES_DESCRIPTION: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
        M_SERIES: 'M-series',
        M_SERIES_DESCRIPTION: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
        EUB_SERIES: 'E-bicycles',
        KICK_SCOOTER_SERIES: 'KQi-series',
        SMART_DESIGN_AND_STYLE: 'A NIU Combination Smart Design + Style',
        SMART_DESIGN_AND_STYLE_DESCRIPTION: 'The MQi-Series is an evolution of the NIU design language that puts a modern twist on heritage scooter design.',
        DESIGN_DETAILS: 'Design details',
        HOME_PAGE: 'Home',
        PRODUCTS: 'Products',
        ABOUT_US: 'About',
        OUR_HISTORY: 'Our history',
        CONTACTS: 'Contacts',
        LANGUAGE: 'Language',
        BUY: 'Buy',
        BATTERY: 'Battery',
        CHARGING_TIME: 'Charging time',
        SPEED: 'Speed',
        TRAVEL_RANGE: 'Travel range',
        POWER: 'Power',
        WEIGHT: 'Weight',
        WHEEL_SIZE: 'Wheel size',
        SPECIFICATIONS: 'Specifications',
        AH: 'Ah',
        HOUR: 'Hour',
        KM_H: 'km/h',
        UP_TO_NUM_KM: 'up to {num} km',
        WATT: 'Watt',
        KG: 'Kg',
        INCH: 'Inch',
        REGION: 'Region',
        DASHBOARD: 'Dashboard',
        COLOR_SCREEN: 'Color screen',
        B_AND_W_SINGLE_BATTERY_SCREEN: 'B&W single battery screen',
        B_AND_W_DUAL_BATTERY_SCREEN: 'B&W dual-batteries screen',
        M_SERIES_B_AND_W_SCREEN: 'M-series B&W screen',
        U_SERIES_B_AND_W_SCREEN: 'U-series B&W screen',
        G_SERIES_B_AND_W_SCREEN: 'G-series B&W screen',
        ABOUT_NIU: 'About NIU',
        NIU_ABOUT_TEXT_P1: 'NIU (NIU Technologies) (NASDAQ: NIU), founded in 2014, is the world’s leading provider of smart urban mobility solutions. NIU is committed to continuously provide global customers more convenient and more environmentally friendly smart urban mobility vehicles to redefine urban mobility and make life better. NIU is a global brand in urban mobility that embraces technology, style and freedom. NIU currently designs manufactures and sells high performance smart electric scooters. NIU currently has a streamlined product portfolio consisting of seven series, NQi, MQi, UQi, NIU Aero, Gova, RQi, TQi, with multiple models and specifications for end consumers such as NIU Power accessories and NIU AERO outdoor sports bicycles. The MQi model and UQi model are the only urban mobility products that have won all seven major international design awards in the past 20 years, including Red Dot and IF in Germany, IDEA in United States, and G Mark in Japan.',
        NIU_ABOUT_TEXT_P2: 'As the world’s leading lithium powered electric two wheeler company, NIU created a new market category for smart electric two wheeler vehicles. NIU’s developed a 4th generation NIU Energy™ lithium battery technology that has a longer lasting battery life, longer range, and more lightweight that is safer and more powerful.',
        NIU_ABOUT_TEXT_P3: 'NIU has maintained a fast pace of growth and has sold an accumulation of over 1 million smart electric two wheeler vehicles worldwide. NIU has expanded into 38 countries with retail stores across cities in Asia, Europe and Latin America. NIU also continues to expand support for sharing businesses globally with 20 sharing operators in 13 countries providing SaaS solutions for electric two wheelers.',
        MISSION: 'Mission',
        NIU_MISSION_TEXT: 'Our mission is to redefine urban mobility and make life better.',
        VISION: 'Vision',
        NIU_VISION_TEXT: 'Our vision is to become the number one brand for urban mobility that is powered by design and technology.',
        ACCESSORIES: 'Accessories',
        TEST_DRIVE: 'Test Drive',
        CONTACT_US: 'Contact us',
        ARMENIA: 'Armenia',

        COMPATIBILITY: 'Compatibility',
        DIMENSION: 'Dimension',
        WEIGHT: 'Weight',
        CAPACITY: 'Capacity',
        MAX_LOAD: 'Max load',
        MODEL_SCALE: 'Model scale',
        SIZES: 'Sizes',
        FRONT_LENGTH: 'Front length',
        BACK_LENGTH: 'Back length',
        WIDTH: 'Width',
        MATERIAL: 'Material',
        TOTALMILEAGE: 'Total distance driven',
        CYCLING_COUNT: 'Today’s Active Riders',
        CARBON: 'Total CO₂ Emissions Saved',
        PLANT_TREES: 'Equivalent Trees Planted',
        NIU_INSPIRE: 'NIU INSPIRE',

        
        MEASURE_MILIMETER: 'mm',
        MEASURE_SANTIMETER: 'sm',
        MEASURE_LITRE: 'ltr',
        MEASURE_KILOGRAM: 'kg',
        MEASURE_GRAMM: 'gm',
        MEASURE_HOUR: 'h',

        ACCESSORY_0_NAME: 'Raincoat',
        ACCESSORY_1_NAME: 'N1S Tail Box',
        ACCESSORY_1_NOTE: 'N1S Tail Box with Holder',
        ACCESSORY_2_NAME: 'N1S Backrest',
        ACCESSORY_3_NAME: 'N1S Windshield',
        ACCESSORY_4_NAME: 'N1S Windshield Large',
        ACCESSORY_5_NAME: 'M1 Backrest',
        ACCESSORY_6_NAME: 'M1 Foot Pegs',
        ACCESSORY_7_NAME: 'Battery Anti-theft bracket（Without lock)',
        ACCESSORY_8_NAME: 'M1 Side Bag',
        ACCESSORY_9_NAME: 'U-series Front basket（Big）with string bag',
        ACCESSORY_10_NAME: 'U-series Middle basket',
        ACCESSORY_11_NAME: 'NIU U1B rear carrier',
        ACCESSORY_12_NAME: 'U Series Duo Seat',
        ACCESSORY_12_NOTE: 'NOT apply for UQi PRO in Europe',
        ACCESSORY_12_CR: 'Need to buy 5UFGB649J then can be mounted available for then can be mounted',
        ACCESSORY_13_NAME: 'U series backrest',
        ACCESSORY_13_NOTE: 'NOT apply for UQi PRO in Europe',
        ACCESSORY_13_CR: 'Need to buy 5UFGB649J then can be mounted available for then can be mounted',
        ACCESSORY_14_NAME: 'U series tail box',
        ACCESSORY_14_NOTE: 'NOT Apply for UM',
        ACCESSORY_14_CR: 'Need to buy 5UFGB649J then can be mounted available for then can be mounted',
        ACCESSORY_15_NAME: 'U1 battery Anti-theft bracket',
        ACCESSORY_16_NAME: 'M+ Tail Box Holder',
        ACCESSORY_17_NAME: 'M+ Backrest',
        ACCESSORY_18_NAME: 'M series tail box',
        ACCESSORY_18_NOTE: 'M series tail box with Holder',
        ACCESSORY_19_NAME: 'M+ Front Bag',
        ACCESSORY_20_NAME: 'M Series Front Bag Ⅱ',
        ACCESSORY_21_NAME: 'Front Bag',
        ACCESSORY_22_NAME: 'Phone Holder',
        ACCESSORY_23_NAME: 'Acorn Nut M5 for phone holder',
        ACCESSORY_23_NOTE: 'It is recommended to buy in multiples of 100',
        ACCESSORY_24_NAME: 'Sticker (reflect light)',
        ACCESSORY_25_NAME: 'Sticker glossy',
        ACCESSORY_26_NAME: 'Sticker frosting',
        ACCESSORY_27_NAME: 'Bike Cover',
        ACCESSORY_28_NAME: 'Neck Warmer',
        ACCESSORY_29_NAME: 'Raincoat',
        ACCESSORY_30_NAME: 'NIU Universal Winter Windproof Leg Cove',
        ACCESSORY_31_NAME: 'NIU THERMAL',
        ACCESSORY_32_NAME: 'Knee pads for cold riding',
        ACCESSORY_33_NAME: 'Rain cushion cover',
        ACCESSORY_34_NAME: 'Sticker',
        ACCESSORY_35_NAME: 'M1 Key Chain',
        ACCESSORY_36_NAME: 'N1 Key Chain',
        ACCESSORY_37_NAME: 'U1 Key Chain',
        ACCESSORY_38_NAME: 'Notebook',
        ACCESSORY_39_NAME: 'N-series scooter plastic pattern',
        ACCESSORY_40_NAME: 'N1S scooter plastic pattern',
        ACCESSORY_41_NAME: 'T-shirt',
        ACCESSORY_41_NOTE: 'Front: Halo, Back: #UNLOCK YOUR CITY',
        ACCESSORY_42_NAME: 'Wrist Band',
        ACCESSORY_43_NAME: 'Keychain',
        ACCESSORY_44_NAME: 'M+ Key Chain',
        ACCESSORY_45_NAME: 'NIU Metal Mug',
        ACCESSORY_46_NAME: 'NIU POWER Metal Mug',
        ACCESSORY_47_NAME: 'NIU super light riding coat',
        ACCESSORY_48_NAME: 'NIU white Sweatshirt',
        ACCESSORY_49_NAME: 'NIU POWER DARKBLUE Sweatshirt',
        ACCESSORY_50_NAME: 'NIU POWER DARKBLUE Sweatshirt (with word)',
        ACCESSORY_51_NAME: 'NIU POWER LIGHTNING Sweatshirt',
        ACCESSORY_52_NAME: 'NIU POWER black hoodie',
        ACCESSORY_53_NAME: 'NIU POWER blue hoodie',
        ACCESSORY_54_NAME: 'NIU POWER white hoodie',
        ACCESSORY_55_NAME: 'NIU POWER SWEATSHIRT JACKET GREY',
        ACCESSORY_56_NAME: 'NIU POWER SWEATSHIRT JACKET BLACK',
        ACCESSORY_57_NAME: 'NIU SUNSCREEN SLEEVES',
        ACCESSORY_58_NAME: 'NIU POWER KEYCHAIN',
        ACCESSORY_59_NAME: 'NIU LOGO KEYCHAIN',
        ACCESSORY_60_NAME: 'NIU KEYCHAIN',
        ACCESSORY_61_NAME: 'NIU RIDING KEYCHAIN',
        ACCESSORY_62_NAME: 'NIU SUNSCREEN CUSHION',
        ACCESSORY_63_NAME: 'NIU SKID RESISTANCE CUSHION',
        ACCESSORY_64_NAME: 'NIU big tail box (red)',
        ACCESSORY_65_NAME: 'NIU big tail box (blue)',
        ACCESSORY_66_NAME: 'NIU big tail box (white)',
        ACCESSORY_66_NOTE: 'M+,  M1 and N-series must be installed with rear brackets',
        ACCESSORY_66_CR: 'Must be installed with rear brackets 5N1HB603J or 511HB617J',
        ACCESSORY_67_NAME: 'NIU big tail box (black)',
        ACCESSORY_67_NOTE: 'M+,  M1 and N-series must be installed with rear brackets',
        ACCESSORY_67_CR: 'Must be installed with rear brackets 5N1HB603J or 511HB617J',
        ACCESSORY_68_NAME: 'NIU big tail box (matte black)',
        ACCESSORY_68_NOTE: 'M+,  M1 and N-series must be installed with rear brackets',
        ACCESSORY_68_CR: 'Must be installed with rear brackets 5N1HB603J or 511HB617J',
        ACCESSORY_69_NAME: 'NIU big tail box (silver)',
        ACCESSORY_69_NOTE: 'M+ series must be installed with rear brackets',
        ACCESSORY_69_CR: 'Must be installed with rear brackets 5MPHB603J',
        ACCESSORY_70_NAME: 'NIU big tail box (gray)',
        ACCESSORY_70_CR: 'must be installed with rear brackets 5MPHB603J',
        ACCESSORY_71_NAME: 'NIU N-series rear bracket',
        ACCESSORY_73_NAME: 'NIU M+ series rear bracket',
        ACCESSORY_74_NAME: 'Cap red',
        ACCESSORY_75_NAME: 'Cap Black T NIU BIG LOGO',
        ACCESSORY_76_NAME: 'Canvas bag black T NIU BIG LOGO',
        ACCESSORY_77_NAME: 'Canvas bag Beige',
        ACCESSORY_78_NAME: 'Canvas bag black T NIU Basic LOGO',
        ACCESSORY_79_NAME: 'NIU BIG LOGO RIDING BAG',
        ACCESSORY_80_NAME: 'Cycling bag',
        ACCESSORY_81_NAME: 'Tire pressure monitor',
        ACCESSORY_82_NAME: 'Raincoat (standart size)',
        ACCESSORY_83_NAME: 'Raincoat (big size)',
        ACCESSORY_84_NAME: 'N-series Windproof Leg Cover',

        E_MAIL: 'E-mail',
        TEL: 'Tel',
        PLEASE_CONTACT_US_TO_BUY: 'Contact us to buy',
        CONCEPT_RQI_SERIES_TITLE: 'RQi Series',
        CONCEPT_RQI_SERIES_DESCRIPTION: 'Urban performance, electric motorcycle',
        CONCEPT_TQI_SERIES_TITLE: 'TQi Series',
        CONCEPT_TQI_SERIES_DESCRIPTION: 'Urban performance, electric commuter',
        CONCEPT_EUB_SERIES_TITLE: 'EUB-01',
        CONCEPT_EUB_SERIES_DESCRIPTION: 'Urban performance, electric bike',
        CONCEPT: 'CONCEPT',
        BATTERY_CHARGE_TIME: 'Charge Time',
        WORLD_NO1_SES: 'World No.1 Smart Electric Scooter',
    }
}