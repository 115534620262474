import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ButtonBase from '@material-ui/core/ButtonBase';
import { loadTranslation } from '../../translation'
import Icon from '../Icon'

import './style.css'

const convertSpecificationDataToView = (spec) => {
    const viewData = []
    if(spec.battery.amp_hour) {
        viewData.push(
            {
                icon: 'specifications/battery.svg',
                title: loadTranslation((translate) => String(translate({id: 'BATTERY'}))),
                value: loadTranslation((translate) => `${spec.battery.amp_hour} ${translate({id: 'AH'})}`)
            }
        )
    }
    if(spec.battery.charge_time) {
        viewData.push(
            {
                icon: 'specifications/battery_charge.svg',
                title: loadTranslation((translate) => String(translate({id: 'BATTERY_CHARGE_TIME'}))),
                value: loadTranslation((translate) => `${spec.battery.charge_time.value} ${translate({id: spec.battery.charge_time.measure})}`)
            }
        )
    }
    if(spec.speed) {
        viewData.push(
            {
                icon: 'specifications/speed.svg',
                title: loadTranslation((translate) => String(translate({id: 'SPEED'}))),
                value: loadTranslation((translate) => `${spec.speed} ${translate({id: 'KM_H'})}`)
            }
        )
    }
    if(spec.range) {
        viewData.push(
            {
                icon: 'specifications/road.svg',
                title: loadTranslation((translate) => String(translate({id: 'TRAVEL_RANGE'}))),
                value: loadTranslation((translate) => `${translate({id: 'UP_TO_NUM_KM'}, {num: spec.range})}`)
            }
        )
    }
    if(spec.motor) {
        viewData.push(
            {
                icon: 'specifications/motor.svg',
                title: loadTranslation((translate) => String(translate({id: 'POWER'}))),
                value: loadTranslation((translate) => `${spec.motor} ${translate({id: 'WATT'}).toLowerCase()}`)
            }
        )
    }
    if(spec.dashboard) {
        viewData.push(
            {
                icon: 'specifications/timer.svg',
                title: loadTranslation((translate) => String(translate({id: 'DASHBOARD'}))),
                value: loadTranslation((translate) => `${translate({id: spec.dashboard})}`)
            },
        )
    }
    return viewData
}

function SpecificationItem(props) {
    return (
        <ButtonBase className='specification-item'>
            <div className='item-inner'>
                <div className='item-icon'>
                    {props.icon}
                </div>
                <div className='item-title'>
                    <Typography variant='subtitle2'>{props.title}</Typography>
                </div>
                <div className='item-value'>
                    <Typography variant='subtitle2'>{props.value}</Typography>
                </div>
            </div>
        </ButtonBase>
    )
}

function SpecificationGrid(props) {
    return (
        <Grid container spaceing={0} className='specification-grid'>
            {
                props.specifications.map((spec, index) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                            <SpecificationItem icon={<Icon icon={spec.icon} />} title={spec.title} value={spec.value} />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default class SectionSpecifications extends React.Component {
    render() {
        
        return loadTranslation( translate => (
            <div className='section-specifications content-container'>
                <div className='content-body'>
                    <div className='title'><Typography variant='h5'>{translate({id: 'SPECIFICATIONS'})}</Typography></div>
                        <SpecificationGrid specifications={convertSpecificationDataToView(this.props.specifications)} />
                </div>
            </div>
        ))
    }
}