import React from 'react'
import { FormattedMessage, injectIntl, createIntl, createIntlCache, IntlProvider} from 'react-intl';
import LOCALES from './locales'
import enMessages from './messages/en'
import ruMessages from './messages/ru'
import amMessages from './messages/am'


const allMessages = {
    ...enMessages,
    ...ruMessages,
    ...amMessages
}


export function TranslationProvider ({children, locale}) {
    return (<IntlProvider locale={locale} messages={allMessages[locale]}>
                {children}
            </IntlProvider>)
}

export const loadTranslation = (fn) => React.createElement(injectIntl(({ intl }) => fn(intl.formatMessage)));

