import LOCALES from '../locales'

export default {
    [LOCALES.RU]: {
        U_SERIES: 'U-серии',
        U_SERIES_DESCRIPTION: 'При вводе в поле перевода латинского словосочетания «lorem ipsum», написанного с двух строчных букв, сервис сообщал',
        N_SERIES: 'N-серии',
        N_SERIES_DESCRIPTION: 'При вводе в поле перевода латинского словосочетания «lorem ipsum», написанного с двух строчных букв, сервис сообщал',
        M_SERIES: 'M-серии',
        M_SERIES_DESCRIPTION: 'При вводе в поле перевода латинского словосочетания «lorem ipsum», написанного с двух строчных букв, сервис сообщал',
        EUB_SERIES: 'Эл. велосипеды',
        KICK_SCOOTER_SERIES: 'KQi-серии',
        SMART_DESIGN_AND_STYLE: 'Комбинация NIU Умный дизайн + Стиль',
        SMART_DESIGN_AND_STYLE_DESCRIPTION: 'Серия MQi - это эволюция языка дизайна NIU, которая вносит современный поворот в традиционный дизайн самокатов.',
        DESIGN_DETAILS: 'Детали дизайна',
        HOME_PAGE: 'Главная',
        PRODUCTS: 'Продукты',
        ABOUT_US: 'О нас',
        OUR_HISTORY: 'Наша история',
        CONTACTS: 'Контакты',
        LANGUAGE: 'Язык',
        BUY: 'Купить',
        BATTERY: 'Аккумулятор',
        CHARGING_TIME: 'Время зарядки',
        SPEED: 'Скорость',
        TRAVEL_RANGE: 'Диапазон путешествия',
        POWER: 'Мощность',
        WEIGHT: 'Вес',
        WHEEL_SIZE: 'Размер колеса',
        SPECIFICATIONS: 'Характеристики',
        AH: 'Ah',
        HOUR: 'Час',
        KM_H: 'км/ч',
        UP_TO_NUM_KM: 'вплоть до {num} км',
        WATT: 'Ватт',
        KG: 'Кг',
        INCH: 'Дюйм',
        REGION: 'Область',
        DASHBOARD: 'Панель приборов',
        COLOR_SCREEN: 'Цветной экран',
        B_AND_W_SINGLE_BATTERY_SCREEN: 'Ч/Б экран с одной батареей',
        B_AND_W_DUAL_BATTERY_SCREEN: 'Ч/Б экран с двумя батареями',
        M_SERIES_B_AND_W_SCREEN: 'Ч/Б экран серии M',
        U_SERIES_B_AND_W_SCREEN: 'Ч/Б экран серии U',
        G_SERIES_B_AND_W_SCREEN: 'Ч/Б экран серии G',
        ABOUT_NIU: 'О NIU',
        NIU_ABOUT_TEXT_P1: 'NIU (NIU Technologies) (NASDAQ: NIU), основанная в 2014 году, является ведущим в мире поставщиком решений для интеллектуальной городской мобильности. NIU стремится постоянно предоставлять клиентам по всему миру более удобные и более экологичные интеллектуальные транспортные средства для городской мобильности, чтобы переопределить городскую мобильность и сделать жизнь лучше. NIU - это глобальный бренд городской мобильности, сочетающий в себе технологии, стиль и свободу. В настоящее время NIU занимается разработкой, производством и продажей высокопроизводительных интеллектуальных электросамокатов. В настоящее время NIU имеет оптимизированный портфель продуктов, состоящий из семи серий: NQi, MQi, UQi, NIU Aero, Gova, RQi, TQi, с множеством моделей и спецификаций для конечных потребителей, таких как аксессуары NIU Power и спортивные велосипеды NIU AERO для активного отдыха.',
        NIU_ABOUT_TEXT_P2: 'Как ведущая в мире компания по производству электромобилей, работающих на литиевых двигателях, NIU создала новую рыночную категорию для интеллектуальных электромобилей. NIU разработала технологию литиевых батарей NIU Energy ™ 4-го поколения, которая обеспечивает более длительный срок службы батареи, большую дальность действия и более легкий вес, более безопасный и мощный.',
        NIU_ABOUT_TEXT_P3: 'NIU поддерживает высокие темпы роста и продала более 1 миллиона интеллектуальных электрических двухколесных транспортных средств по всему миру. NIU расширился в 38 странах с розничными магазинами в городах Азии, Европы и Латинской Америки. NIU также продолжает расширять поддержку совместного бизнеса по всему миру с 20 операторами совместного использования в 13 странах, предоставляющими SaaS-решения для электрических двухколесных транспортных средств.',
        MISSION: 'Миссия',
        NIU_MISSION_TEXT: 'Наша миссия - изменить определение городской мобильности и сделать жизнь лучше.',
        VISION: 'Видение',
        NIU_VISION_TEXT: 'Наше видение - стать брендом номер один в сфере городской мобильности, основанной на дизайне и технологиях.',
        ACCESSORIES: 'Аксессуары',
        TEST_DRIVE: 'Тест-драйв',
        CONTACT_US: 'Связаться с нами',
        ARMENIA: 'Армения',

        COMPATIBILITY: 'Совместимость',
        DIMENSION: 'Размер',
        WEIGHT: 'Вес',
        CAPACITY: 'Вместимость',
        MAX_LOAD: 'Макс. Нагрузка',
        MODEL_SCALE: 'Масштаб модели',
        SIZES: 'Размеры',
        FRONT_LENGTH: 'Длина спереди',
        BACK_LENGTH: 'Длина спины',
        WIDTH: 'Ширина',
        MATERIAL: 'Материал',
        TOTALMILEAGE: 'Общее пройденное расстояние',
        CYCLING_COUNT: 'Сегодняшние активные райдеры',
        CARBON: 'Общее сокращение выбросов CO₂',
        PLANT_TREES: 'Высажены аналогичные деревья',
        NIU_INSPIRE: 'Вдохновение NIU',

        MEASURE_MILIMETER: 'мм',
        MEASURE_SANTIMETER: 'см',
        MEASURE_LITRE: 'л',
        MEASURE_KILOGRAM: 'кг',
        MEASURE_GRAMM: 'г',
        MEASURE_HOUR: 'ч.',

        ACCESSORY_0_NAME: 'Плащ',
        ACCESSORY_1_NAME: 'N1S Хвостовая коробка',
        ACCESSORY_1_NOTE: 'N1S Хвостовая коробка с держателем',
        ACCESSORY_2_NAME: 'N1S Спинка',
        ACCESSORY_3_NAME: 'N1S Лобовое стекло',
        ACCESSORY_4_NAME: 'N1S Большое лобовое стекло',
        ACCESSORY_5_NAME: 'M1 Спинка',
        ACCESSORY_6_NAME: 'M1 Подножки',
        ACCESSORY_7_NAME: 'Кронштейн для защиты от кражи аккумулятора (без замка)',
        ACCESSORY_8_NAME: 'M1 Боковая сумка',
        ACCESSORY_9_NAME: 'U-series Передняя корзина (большая) с авоськой',
        ACCESSORY_10_NAME: 'U-series Средняя корзина',
        ACCESSORY_11_NAME: 'NIU U1B задний носитель',
        ACCESSORY_12_NAME: 'U-series Сиденье Duo',
        ACCESSORY_12_NOTE: 'Не применяется для UQi PRO в Европе',
        ACCESSORY_12_CR: 'Необходимо купить 5UFGB649J, затем можно установить.',
        ACCESSORY_13_NAME: 'U-series Спинка',
        ACCESSORY_13_NOTE: 'Не применяется для UQi PRO в Европе',
        ACCESSORY_13_CR: 'Необходимо купить 5UFGB649J, затем можно установить.',
        ACCESSORY_14_NAME: 'U-series Хвостовая коробка',
        ACCESSORY_14_NOTE: 'Не применяется для UM',
        ACCESSORY_14_CR: 'Необходимо купить 5UFGB649J, затем можно установить.',
        ACCESSORY_15_NAME: 'U1 Противоугонный кронштейн аккумуляторной батареи',
        ACCESSORY_16_NAME: 'M+ Держатель хвостовой коробки',
        ACCESSORY_17_NAME: 'M+ Спинка',
        ACCESSORY_18_NAME: 'M series Хвостовая коробка',
        ACCESSORY_18_NOTE: 'M series Хвостовой ящик с держателем',
        ACCESSORY_19_NAME: 'M+ Передняя сумка',
        ACCESSORY_20_NAME: 'M Series Передняя сумка Ⅱ',
        ACCESSORY_21_NAME: 'Передняя сумка',
        ACCESSORY_22_NAME: 'Держатель для телефона',
        ACCESSORY_23_NAME: 'Acorn Nut M5 для держателя телефона',
        ACCESSORY_23_NOTE: 'Рекомендуется покупать в количестве, кратном 100',
        ACCESSORY_24_NAME: 'Наклейка (отражает свет)',
        ACCESSORY_25_NAME: 'Наклейка глянцевая',
        ACCESSORY_26_NAME: 'Наклейка глазурь',
        ACCESSORY_27_NAME: 'Чехол для велосипеда',
        ACCESSORY_28_NAME: 'Специальный шарф шеи',
        ACCESSORY_29_NAME: 'Плащ',
        ACCESSORY_30_NAME: 'Универсальная зимняя ветрозащитная бухта для ног NIU',
        ACCESSORY_31_NAME: 'Перчатки NIU',
        ACCESSORY_32_NAME: 'Наколенники для холодной езды',
        ACCESSORY_33_NAME: 'Чехол для защиты от дождя',
        ACCESSORY_34_NAME: 'Наклейка',
        ACCESSORY_35_NAME: 'M1 Брелок',
        ACCESSORY_36_NAME: 'N1 Брелок',
        ACCESSORY_37_NAME: 'U1 Брелок',
        ACCESSORY_38_NAME: 'Блокнот',
        ACCESSORY_39_NAME: 'N-series самокат пластиковая модель',
        ACCESSORY_40_NAME: 'N1S самокат пластиковая модель',
        ACCESSORY_41_NAME: 'Футболка',
        ACCESSORY_41_NOTE: 'Спереди: Halo, сзади: #UNLOCK YOUR CITY',
        ACCESSORY_42_NAME: 'Запястье',
        ACCESSORY_43_NAME: 'Брелок',
        ACCESSORY_44_NAME: 'M+ Брелок',
        ACCESSORY_45_NAME: 'NIU Металлическая кружка',
        ACCESSORY_46_NAME: 'NIU POWER Металлическая кружка',
        ACCESSORY_47_NAME: 'NIU суперлегкое пальто для верховой езды',
        ACCESSORY_48_NAME: 'NIU белая толстовка',
        ACCESSORY_49_NAME: 'NIU POWER Толстовка темно-синего цвета',
        ACCESSORY_50_NAME: 'NIU POWER Толстовка темно-синего цвета (со словом)',
        ACCESSORY_51_NAME: 'NIU POWER Толстовка с молнией',
        ACCESSORY_52_NAME: 'NIU POWER черная толстовка',
        ACCESSORY_53_NAME: 'NIU POWER синяя толстовка',
        ACCESSORY_54_NAME: 'NIU POWER белая толстовка',
        ACCESSORY_55_NAME: 'NIU POWER серая толстовка',
        ACCESSORY_56_NAME: 'NIU POWER черная толстовка',
        ACCESSORY_57_NAME: 'NIU Солнцезащитные рукава',
        ACCESSORY_58_NAME: 'NIU POWER Брелок',
        ACCESSORY_59_NAME: 'NIU LOGO Брелок',
        ACCESSORY_60_NAME: 'NIU Брелок',
        ACCESSORY_61_NAME: 'NIU RIDING Брелок',
        ACCESSORY_62_NAME: 'NIU Солнцезащитная подушка',
        ACCESSORY_63_NAME: 'NIU Подушка для сопротивления скольжению',
        ACCESSORY_64_NAME: 'NIU большой хвостовой ящик (красный)',
        ACCESSORY_65_NAME: 'NIU большой хвостовой ящик (синий)',
        ACCESSORY_66_NAME: 'NIU большой хвостовой ящик (белый)',
        ACCESSORY_66_NOTE: 'Для M+, M1 и N-series должен быть установлен с задними кронштейнами',
        ACCESSORY_66_CR: 'Устанавливается с задними кронштейнами 5N1HB603J или 511HB617J',
        ACCESSORY_67_NAME: 'NIU большой хвостовой ящик (черный)',
        ACCESSORY_67_NOTE: 'Для M+, M1 и N-series должен быть установлен с задними кронштейнами',
        ACCESSORY_67_CR: 'Устанавливается с задними кронштейнами 5N1HB603J или 511HB617J',
        ACCESSORY_68_NAME: 'NIU большой хвостовой бокс (матовый черный)',
        ACCESSORY_68_NOTE: 'Для M+, M1 и N-series должен быть установлен с задними кронштейнами',
        ACCESSORY_68_CR: 'Устанавливается с задними кронштейнами 5N1HB603J или 511HB617J',
        ACCESSORY_69_NAME: 'NIU большой хвостовой бокс (серебристый)',
        ACCESSORY_69_NOTE: 'Для  M+ series должен быть установлен с задними кронштейнами',
        ACCESSORY_69_CR: 'Необходимо установить с задними кронштейнами 5MPHB603J',
        ACCESSORY_70_NAME: 'NIU большой хвостовой ящик (серый)',
        ACCESSORY_70_CR: 'Необходимо установить с задними кронштейнами 5MPHB603J',
        ACCESSORY_71_NAME: 'NIU N-series Задний кронштейн',
        ACCESSORY_73_NAME: 'NIU M+ series Задний кронштейн',
        ACCESSORY_74_NAME: 'Красная кепка',
        ACCESSORY_75_NAME: 'Черная кепка NIU с логотипом',
        ACCESSORY_76_NAME: 'Черная Холщовая сумка NIU с логотипом',
        ACCESSORY_77_NAME: 'Сумка из плотной ткани (Бежевая)',
        ACCESSORY_78_NAME: 'NIU Сумка из плотной ткани черного цвета',
        ACCESSORY_79_NAME: 'NIU Сумка для езды с логотипом',
        ACCESSORY_80_NAME: 'Сумка для велоспорта',
        ACCESSORY_81_NAME: 'Датчик давления в шинах',
        ACCESSORY_82_NAME: 'Дождевик (стандартный размер)',
        ACCESSORY_83_NAME: 'Дождевик (большой размер)',
        ACCESSORY_84_NAME: 'N-series Ветрозащитный чехол для ног',

        E_MAIL: 'Е-мейл',
        TEL: 'Тел.',
        PLEASE_CONTACT_US_TO_BUY: 'Свяжитесь с нами, чтобы купить',

        CONCEPT_RQI_SERIES_TITLE: 'RQi Серии',
        CONCEPT_RQI_SERIES_DESCRIPTION: 'Городская производительность, электрический мотоцикл.',
        CONCEPT_TQI_SERIES_TITLE: 'TQi Серии',
        CONCEPT_TQI_SERIES_DESCRIPTION: 'Городская производительность, электрический каммутатор.',
        CONCEPT_EUB_SERIES_TITLE: 'EUB-01',
        CONCEPT_EUB_SERIES_DESCRIPTION: 'Городская производительность, электрический велосипед',
        CONCEPT: 'КОНЦЕПЦИЯ',
        BATTERY_CHARGE_TIME: 'Перезарядка',
        WORLD_NO1_SES: 'Умный электрический самокат №1 в мире',
    }
}