import React from 'react'
import { Link } from 'react-router-dom'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Image from '../Image'

import './style.css'

export default function ImageCard (props) {
    return (
        <div className={`image-card-container${props.className? ' '+props.className:''}`}>
            <Link to={props.href} className='link'>
                <Card className='image-card' onClick={props.onClick}>
                    <CardActionArea>
                        {/* <CardMedia
                            className='image-card-image'
                            image={props.src}
                        /> */}
                        <Image className='image-card-image' src={props.src} />
                        <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {props.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {props.subtitle}
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Link>
        </div>
    )
}