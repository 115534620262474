export const DEVICE_TYPES = {
    MOBILE: 'MOBILE',
    TABLET: 'TABLET',
    DESKTOP: 'DESKTOP'
}

export const getDeviceType = () => {
    if (window.innerWidth <= 600) {
        return DEVICE_TYPES.MOBILE
    } else if (window.innerWidth <= 960) {
        return DEVICE_TYPES.TABLET
    } else {
        return DEVICE_TYPES.DESKTOP
    }
}