import React from 'react'
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkButton from '../LinkButton'
import AppLogo from '../AppLogo'

import './style.css'

class ButtonCollapse extends React.Component {
    state = {
        open: false
    }

    toggleCollapse = (value) => {
        this.setState({open: value})
    }

    render() {
        const { open } = this.state
        const { label, className, content } = this.props
        return (
            <div className={`list-item-collapse${className? ' ' + className:''}`}>
                <LinkButton onClick={() => this.toggleCollapse(!open)} endIcon={<ExpandMoreIcon />}>{label}</LinkButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {content}
                </Collapse>
            </div>
        )
    }
}

export default class AsieMenu extends React.Component {
    renderMenu = (nodes) => (
        nodes.map((node, index) => {
            if (node.content) {
                return <ButtonCollapse key={index} className="aside-menu-item" label={node.label} content={node.content} />
            } else if (node.href) {
                return <LinkButton key={index} className="aside-menu-item" onClick={() => this.props.onClose()} href={node.href}>{node.label}</LinkButton>
            }
            return false
        })
    )

    render() {
        const { open, items } = this.props

        return (
            <Drawer
                className="aside-menu"
                anchor="left"
                open={open}
                classes={{
                    paper: 'aside-menu-paper',
                }}
                onClose={() => this.props.onClose()}>
                    <div className='aside-menu-header'>
                        <IconButton onClick={() => this.props.onClose()}>
                            <CloseIcon />
                        </IconButton>
                        <AppLogo rootClassName="aside-menu-logo" />
                    </div>
                    <Divider />
                        <List className="menu-list-view">
                            {
                                this.renderMenu(items)
                            }
                        </List>
                    </Drawer>
        )
    }
}