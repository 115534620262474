import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../Image'
import './style.css'

export default function AppLogo(props) {
    var logoType = props.type? props.type:'default'
    var href = props.href? props.href:'/'
    var logoUrl = false
    switch(logoType) {
        case 'gray':
            logoUrl = 'icon.png'
            break;
        default:
            logoUrl = 'icon.png'
            break
    }
    return(
        <div className={`app-logo${props.rootClassName? ' ' + props.rootClassName:''}`}>
            <Link to={href}>
                <Image src={logoUrl} className={props.className} alt="logo" simple={true}/>
            </Link>
        </div>
    )
}