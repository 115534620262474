import React from 'react'
import Image from '../Image'
import { TRADEMARK } from '../../helpers/trademark'

import './style.css'

export default function Trademark(props) {
    console.log("Loading trademark: " + "  ---- "  + TRADEMARK[props.name])
    return (
        <div className="trademark">
            <Image src={TRADEMARK[props.name]} alt='trademark' />
        </div>
    )
}