import React from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'

import './style.css'

export default function ColorSelector(props) {
    return (
        <div className='color-selector'>
            {
                props.colors? props.colors.map((color, index) => <ButtonBase key={index} className={`color-btn ${color}${color === props.activeColor? ' active':''}`} onClick={() => props.onClick? props.onClick(color):false} />):false
            }
        </div>
    )
}