import React from 'react'
import AppContext from '../../contexts/AppContext'
import LanguageIcon from '@material-ui/icons/Language';
import LinkDropdown from '../LinkDropdown'
import { languages } from '../../translation/locales'

import './style.css'

export default class LanguageSelector extends React.Component {
    static contextType = AppContext

    render() {
        const { language, setApplicationLanguage } = this.context
        return (
            <div className="language-select">
                <LinkDropdown className="language-select-desktop" value={language} onChange={(value) => setApplicationLanguage(value)} label={<LanguageIcon />} items={languages} />
                <LinkDropdown className="language-select-mobile" value={language} onChange={(value) => setApplicationLanguage(value)} label={<LanguageIcon />} items={languages} />
            </div>
        )
    }
}