export default {
    contactInfo: {
        emails: [
            'info@niu.am',
            'niu.armenia@gmail.com'
        ],
        tel: [
        ]
    },
    officialGroups: {
        'facebook': 'https://www.facebook.com/NIU.Armenia',
        'instagram': 'https://www.instagram.com/niu.armenia',
        'youtube': 'https://www.youtube.com/niumobility',
        'twitter': false,
    }
}