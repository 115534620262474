import LOCALES from '../locales'

export default {
    [LOCALES.AM]: {
        U_SERIES: 'U-սերիա',
        U_SERIES_DESCRIPTION: 'Գիտաժողովին մասնակցել են ավելի քան երկու տասնյակ ուսանողներ Հայաստանի տարբեր մարզերից',
        N_SERIES: 'N-սերիա',
        N_SERIES_DESCRIPTION: 'Գիտաժողովին մասնակցել են ավելի քան երկու տասնյակ ուսանողներ Հայաստանի տարբեր մարզերից',
        M_SERIES: 'M-սերիա',
        M_SERIES_DESCRIPTION: 'Գիտաժողովին մասնակցել են ավելի քան երկու տասնյակ ուսանողներ Հայաստանի տարբեր մարզերից',
        EUB_SERIES: 'Էլ․ հեծանիվներ',
        KICK_SCOOTER_SERIES: 'KQi-սերիա',
        SMART_DESIGN_AND_STYLE: 'NIU - Խելացի դիզայնի և Ոճի համադրություն',
        SMART_DESIGN_AND_STYLE_DESCRIPTION: 'MQi շարքը NIU դիզայնի լեզվի էվոլյուցիա է, որը ժամանակակից շրջադարձ է բերում սկուտերի ավանդական դիզայնին:',
        DESIGN_DETAILS: 'Դիզայնի մանրամասները',
        HOME_PAGE: 'Գլխավոր',
        PRODUCTS: 'Ապրանքներ',
        ABOUT_US: 'Մեր մասին',
        OUR_HISTORY: 'Մեր պատմությունը',
        CONTACTS: 'Հետադարձ կապ',
        LANGUAGE: 'Լեզու',
        BUY: 'Գնել',
        BATTERY: 'Մարտկոց',
        CHARGING_TIME: 'Լիցքավորման ժամանակ',
        SPEED: 'Արագություն',
        TRAVEL_RANGE: 'Ճանապ. տիրույթ',
        POWER: 'Ուժ',
        WEIGHT: 'Քաշ',
        WHEEL_SIZE: 'Անիվի չափ',
        SPECIFICATIONS: 'Բնութագիր',
        AH: 'Ամ/ժ',
        HOUR: 'Ժամ',
        KM_H: 'կմ/ժ',
        UP_TO_NUM_KM: 'մինչեւ {num} կմ',
        WATT: 'Վտտ',
        KG: 'Կգ',
        INCH: 'Դյույմ',
        REGION: 'Շրջան',
        DASHBOARD: 'Վահանակ',
        COLOR_SCREEN: 'Գունավոր էկրան',
        B_AND_W_SINGLE_BATTERY_SCREEN: 'Սև/Սպիտակ էկրան մեկ մարտկոցով',
        B_AND_W_DUAL_BATTERY_SCREEN: 'Սև/Սպիտակ էկրան երկու մարտկոցով',
        M_SERIES_B_AND_W_SCREEN: 'M շարքի Սև/Սպիտակ էկրան',
        U_SERIES_B_AND_W_SCREEN: 'U շարքի Սև/Սպիտակ էկրան',
        G_SERIES_B_AND_W_SCREEN: 'G շարքի Սև/Սպիտակ էկրան',
        ABOUT_NIU: 'NIU-ի մասին',
        NIU_ABOUT_TEXT_P1: 'NIU (NIU Technologies) (NASDAQ: NIU), հիմնադրվել է 2014 թ., Քաղաքային շարժունակության խելացի լուծումների աշխարհի առաջատար մատակարարն է: NIU- ն պարտավորվում է շարունակաբար տրամադրել գլոբալ հաճախորդներին ավելի հարմարավետ և էկոլոգիապես մաքուր քաղաքային շարժունակության խելացի փոխադրամիջոցներ `քաղաքային շարժունակությունը վերասահմանելու և կյանքը ավելի լավ դարձնելու համար: NIU- ը քաղաքային շարժունակության համաշխարհային ապրանքանիշ է, որն ընդգրկում է տեխնոլոգիան, ոճը և ազատությունը: NIU- ն այժմ զբաղվում է բարձրորակ խելացի էլեկտրական սկուտերների արտադրությամբ և վաճառքով: Ներկայումս NIU- ն ունի պարզեցված ապրանքների պորտֆել, որը բաղկացած է յոթ սերիաներից ՝ NQi, MQi, UQi, NIU Aero, Gova, RQi, TQi, վերջնական սպառողների բազմաթիվ մոդելներով և բնութագրերով, ինչպիսիք են NIU Power պարագաներն ու NIU AERO բացօթյա մարզական հեծանիվները:',
        NIU_ABOUT_TEXT_P2: 'Որպես լիթիումով աշխատող երկանիվ էլեկտրականությամբ զբաղվող աշխարհի առաջատար ընկերություն ՝ NIU- ն ստեղծեց շուկայի նոր կատեգորիա խելացի էլեկտրական երկանիվ տրանսպորտային միջոցների համար: NIU- ն մշակել է 4-րդ սերնդի NIU Energy ™ լիթիումի մարտկոցի տեխնոլոգիա, որն ունի ավելի երկար մարտկոցի կյանք, ավելի երկար տիրույթ և ավելի թեթև քաշ, որն ավելի անվտանգ և հզոր է:',
        NIU_ABOUT_TEXT_P3: 'NIU- ն պահպանել է աճի արագ տեմպը և վաճառել ամբողջ աշխարհում ավելի քան 1 միլիոն խելացի էլեկտրական երկու անվանի տրանսպորտային միջոցների կուտակում: NIU- ն ընդլայնվել է 38 երկրում `մանրածախ խանութներով` Ասիայի, Եվրոպայի և Լատինական Ամերիկայի քաղաքներում: NIU- ն նաև շարունակում է ընդլայնել աջակցությունը 13 երկրներում 20 բաժանող օպերատորների հետ բիզնեսի փոխանակմանը `ապահովելով SaaS լուծումներ էլեկտրական երկանիվ անվաբջջների համար:',
        MISSION: 'Առաքելություն',
        NIU_MISSION_TEXT: 'Մեր առաքելությունն է վերասահմանել քաղաքային շարժունակությունը և բարելավել կյանքը:',
        VISION: 'Տեսլական',
        NIU_VISION_TEXT: 'Մեր տեսլականն է դառնալ քաղաքային շարժունակության թիվ մեկ ապրանքանիշը, որը սնուցվում է դիզայնով և տեխնոլոգիայով:',
        ACCESSORIES: 'Աքսեսուարներ',
        TEST_DRIVE: 'Փորձնական վարում',
        CONTACT_US: 'Հետադարձ կապ',
        ARMENIA: 'Հայաստան',

        COMPATIBILITY: 'Համատեղելիություն',
        DIMENSION: 'Չափ',
        WEIGHT: 'Քաշ',
        CAPACITY: 'Պարունակելիություն',
        MAX_LOAD: 'Մաքս․ բեռնելիություն',
        MODEL_SCALE: 'Մոդելի մասշտաբ',
        SIZES: 'Չափերը',
        FRONT_LENGTH: 'Երկարությունը առջևից',
        BACK_LENGTH: 'Երկարությունը ետևից',
        WIDTH: 'Լայնություն',
        MATERIAL: 'Մատերիալ',
        TOTALMILEAGE: 'Ընդհանուր անցած հեռավորությունը',
        CYCLING_COUNT: 'Այսօրվա ակտիվ վարորդները',
        CARBON: 'CO₂ արտանետումների ընդհանուր նվազեցում',
        PLANT_TREES: 'Նմանատիպ ծառեր են տնկվել',
        NIU_INSPIRE: 'NIU-Ի ՈԳԵՇՆՉՈՒՄԸ',

        
        MEASURE_MILIMETER: 'մմ',
        MEASURE_SANTIMETER: 'սմ',
        MEASURE_LITRE: 'լ',
        MEASURE_KILOGRAM: 'կգ',
        MEASURE_GRAMM: 'գ',
        MEASURE_HOUR: 'ժ․',

        ACCESSORY_0_NAME: 'Անձրևանոց',
        ACCESSORY_1_NAME: 'N1S Հետևի բեռնախցիկ',
        ACCESSORY_1_NOTE: 'N1S Հետևի բեռնախցիկ իր բռնիչով',
        ACCESSORY_2_NAME: 'N1S Աթոռի հենարան',
        ACCESSORY_3_NAME: 'N1S Առջևի ապակի',
        ACCESSORY_4_NAME: 'N1S Առջևի մեծ ապակի',
        ACCESSORY_5_NAME: 'M1 Աթոռի հենարան',
        ACCESSORY_6_NAME: 'M1 Ոտնադիրներ',
        ACCESSORY_7_NAME: 'Հարմարանք մարտկոցը գողությունից պաշտպանելու համար (առանց կողպեքի)',
        ACCESSORY_8_NAME: 'M1 Կողային պայուսակ',
        ACCESSORY_9_NAME: 'U-series Առջևի զամբյուղ (մեծ)',
        ACCESSORY_10_NAME: 'U-series Միջին զամբյուղ',
        ACCESSORY_11_NAME: 'NIU U1B Հետևի կրիչ',
        ACCESSORY_12_NAME: 'U-series Նստարան Duo',
        ACCESSORY_12_NOTE: 'Եվրոպայում UQi PRO- ի համար կիրառելի չէ',
        ACCESSORY_12_CR: 'Հարկավոր է գնել 5UFGB649J տեղադրելու համար',
        ACCESSORY_13_NAME: 'U-series Նստատեղի հենարան',
        ACCESSORY_13_NOTE: 'Եվրոպայում UQi PRO- ի համար կիրառելի չէ',
        ACCESSORY_13_CR: 'Հարկավոր է գնել 5UFGB649J տեղադրելու համար',
        ACCESSORY_14_NAME: 'U-series Хвостовая коробка',
        ACCESSORY_14_NOTE: 'UM-ի համար կիրառելի չէ',
        ACCESSORY_14_CR: 'Հարկավոր է գնել 5UFGB649J տեղադրելու համար',
        ACCESSORY_15_NAME: 'U1 Հարմարանք մարտկոցը գողությունից պաշտպանելու համար',
        ACCESSORY_16_NAME: 'M+ Հետևի բեռնախցիկը կրող հարմարանք',
        ACCESSORY_17_NAME: 'M+ Նստատեղի հենարան',
        ACCESSORY_18_NAME: 'M series Հետևի բեռնախցիկ',
        ACCESSORY_18_NOTE: 'M series Հետևի բեռնախցիկ կրիչով',
        ACCESSORY_19_NAME: 'M+ Առջևի պայուսակ',
        ACCESSORY_20_NAME: 'M Series Առջևի պայուսակ Ⅱ',
        ACCESSORY_21_NAME: 'Առջևի պայուսակ',
        ACCESSORY_22_NAME: 'Հեռախոսի կրիչ',
        ACCESSORY_23_NAME: 'Acorn Nut M5 հեռախոսի կրիչի համար',
        ACCESSORY_23_NOTE: 'Խորհուրդ է տրվում գնել մեծ քանակներով (100+)',
        ACCESSORY_24_NAME: 'Կպչուն նկարներ (անդրադարձնում է լույսը)',
        ACCESSORY_25_NAME: 'Կպչուն նկարներ (փայլուն)',
        ACCESSORY_26_NAME: 'Կպչուն նկարներ',
        ACCESSORY_27_NAME: 'Հեծանիվների ծածկոց',
        ACCESSORY_28_NAME: 'Հատուկ ծածկոց վզի համար',
        ACCESSORY_29_NAME: 'Անձրևանոց',
        ACCESSORY_30_NAME: 'Ունիվերսալ ձմեռային հողմակայուն ոտքի ծածկ NIU',
        ACCESSORY_31_NAME: 'Ձեռնոցներ NIU',
        ACCESSORY_32_NAME: 'Ծածկոց ծնկների համար',
        ACCESSORY_33_NAME: 'Ծածկոց անձրևից պաշտպանվելու համար',
        ACCESSORY_34_NAME: 'Կպչուն նկար',
        ACCESSORY_35_NAME: 'M1 Բրիլոկ',
        ACCESSORY_36_NAME: 'N1 Բրիլոկ',
        ACCESSORY_37_NAME: 'U1 Բրիլոկ',
        ACCESSORY_38_NAME: 'Բլոկնոտ',
        ACCESSORY_39_NAME: 'N-series սկուտերի պլաստիկ մոդել',
        ACCESSORY_40_NAME: 'N1S սկուտերի պլաստիկ մոդել',
        ACCESSORY_41_NAME: 'Շապիկ',
        ACCESSORY_41_NOTE: 'Առջևից: Halo, հետևից: #UNLOCK YOUR CITY',
        ACCESSORY_42_NAME: 'Թևնոց',
        ACCESSORY_43_NAME: 'Բրիլոկ',
        ACCESSORY_44_NAME: 'M+ Բրիլոկ',
        ACCESSORY_45_NAME: 'NIU Մետաղյա բաժակ',
        ACCESSORY_46_NAME: 'NIU POWER Մետաղյա բաժակ',
        ACCESSORY_47_NAME: 'NIU գերթեթեվ վերարկու',
        ACCESSORY_48_NAME: 'NIU սպիտակ շապիկ',
        ACCESSORY_49_NAME: 'NIU POWER մուգ կապույտ շապիկ',
        ACCESSORY_50_NAME: 'NIU POWER մուգ կապույտ շապիկ (բառով)',
        ACCESSORY_51_NAME: 'NIU POWER Կայծակով շապիկ',
        ACCESSORY_52_NAME: 'NIU POWER սև շապիկ',
        ACCESSORY_53_NAME: 'NIU POWER կապույտ շապիկ',
        ACCESSORY_54_NAME: 'NIU POWER սպիտակ շապիկ',
        ACCESSORY_55_NAME: 'NIU POWER մոխրագույն շապիկ',
        ACCESSORY_56_NAME: 'NIU POWER սև շապիկ',
        ACCESSORY_57_NAME: 'NIU Արևապաշտպանիչ թևերի համար',
        ACCESSORY_58_NAME: 'NIU POWER Բրիլոկ',
        ACCESSORY_59_NAME: 'NIU LOGO Բրիլոկ',
        ACCESSORY_60_NAME: 'NIU Բրիլոկ',
        ACCESSORY_61_NAME: 'NIU RIDING Բրիլոկ',
        ACCESSORY_62_NAME: 'NIU Արըապաշտպանիև բարձ',
        ACCESSORY_63_NAME: 'NIU Սայթաքումը դիմադրելու բարձիկ',

        ACCESSORY_64_NAME: 'NIU մեծ հետևի բեռնախցիկ (կարմիր)',
        ACCESSORY_65_NAME: 'NIU մեծ հետևի բեռնախցիկ (կապույտ)',
        ACCESSORY_66_NAME: 'NIU մեծ հետևի բեռնախցիկ (սպիտակ)',
        ACCESSORY_66_NOTE: 'M+, M1 և N-series- ների համար պետք է տեղադրել հետևի կրիչ-հարմարանքով',
        ACCESSORY_66_CR: 'Տեղադրվում է հետին հարմարանքներով 5N1HB603J կամ 511HB617J',
        ACCESSORY_67_NAME: 'NIU մեծ հետևի բեռնախցիկ (սև)',
        ACCESSORY_67_NOTE: 'M+, M1 և N-series- ների համար պետք է տեղադրել հետևի կրիչ-հարմարանքով',
        ACCESSORY_67_CR: 'Տեղադրվում է հետին հարմարանքներով 5N1HB603J կամ 511HB617J',
        ACCESSORY_68_NAME: 'NIU մեծ հետևի բեռնախցիկ (անփայլ սև)',
        ACCESSORY_68_NOTE: 'M+, M1 և N-series- ների համար պետք է տեղադրել հետևի կրիչ-հարմարանքով',
        ACCESSORY_68_CR: 'Տեղադրվում է հետին հարմարանքներով 5N1HB603J կամ 511HB617J',
        ACCESSORY_69_NAME: 'NIU մեծ հետևի բեռնախցիկ (արծաթագույն)',
        ACCESSORY_69_NOTE: 'M+ series-ի համար պետք է տեղադրել հետևի կրիչ-հարմարանքների հետ',
        ACCESSORY_69_CR: 'Անհրաժեշտ է տեղադրել հետևի կրիչ-հարմարանքով (5MPHB603J)',
        ACCESSORY_70_NAME: 'NIU մեծ հետևի բեռնախցիկ (մոխրագույն)',
        ACCESSORY_70_CR: 'Անհրաժեշտ է տեղադրել հետևի կրիչ-հարմարանքով (5MPHB603J)',
        ACCESSORY_71_NAME: 'NIU N-series հետևի կրիչ-հարմարանք',
        ACCESSORY_73_NAME: 'NIU M+ series հետևի կրիչ-հարմարանք',
        ACCESSORY_74_NAME: 'Կարմիր գլխարկ',
        ACCESSORY_75_NAME: 'Սև գլխարկ NIU տարբերանշանով',
        ACCESSORY_76_NAME: 'Սև կտավե պայուսակ NIU տարբերանշանով',
        ACCESSORY_77_NAME: 'Պայուսակ խիտ կտորից (Բեժ)',
        ACCESSORY_78_NAME: 'NIU Սև պայուսակ խիտ կտորից',
        ACCESSORY_79_NAME: 'NIU Տարբերանշանով պայուսակ',
        ACCESSORY_80_NAME: 'Պայուսակ հեծանիվի համար',
        ACCESSORY_81_NAME: 'Անվադողերի ճնշման սենսոր',
        ACCESSORY_82_NAME: 'Անձրևանոց (ստանդարտ չափ)',
        ACCESSORY_83_NAME: 'Անձրևանոց (մեծ չափ)',
        ACCESSORY_84_NAME: 'N-series Ոտքերի ծածկոց քամուց պաշտպանվելու համար',

        E_MAIL: 'Էլ․ հասցե',
        TEL: 'Հեռ.',
        PLEASE_CONTACT_US_TO_BUY: 'Գնելու համար կապնվեք մեզ հետ',

        CONCEPT_RQI_SERIES_TITLE: 'RQi Սերիաներ',
        CONCEPT_RQI_SERIES_DESCRIPTION: 'Քաղաքային արդյունավետություն, էլեկտրական մոտոցիկլ',
        CONCEPT_TQI_SERIES_TITLE: 'TQi Սերիաներ',
        CONCEPT_TQI_SERIES_DESCRIPTION: 'Քաղաքային արդյունավետություն, էլեկտրական կամուտատոր',
        CONCEPT_EUB_SERIES_TITLE: 'EUB-01',
        CONCEPT_EUB_SERIES_DESCRIPTION: 'Քաղաքային արդյունավետություն, էլեկտրական հեծանիվ',
        CONCEPT: 'ԿՈՆՑԵՊՏ',
        BATTERY_CHARGE_TIME: 'Լիցքավորում',
        WORLD_NO1_SES: 'Թիվ 1 էլեկտրական սմարթ սկուտերները աշխարհում'
    }
}