import React from 'react'
import Typography from '@material-ui/core/Typography'
import Image from '../Image'
import Title from '../Title'
import { loadTranslation } from '../../translation' 

import './style.css'

export default function PageAbout (props) {
    return loadTranslation( translate => (
        <div className='page-about content-container'>
            <Title pageTitle={translate({id: `ABOUT_US`})} siteTitle={`NIU ${translate({id: 'ARMENIA'})}`} />
            <Image className='img-banner' src={'/section/about.jpg'} />
            <div className='content-body'>
                <div className='title-container'>
                    <Typography className='title' variant='h5'>{translate({id: `ABOUT_NIU`})}</Typography>                            
                </div>
                <div className='info-section'>
                    {translate({id: `NIU_ABOUT_TEXT_P1`})}
                </div>
                <div className='info-section'>
                    {translate({id: `NIU_ABOUT_TEXT_P2`})}
                </div>
                <div className='info-section'>
                    {translate({id: `NIU_ABOUT_TEXT_P3`})}
                </div>
                <div className='title-container'>
                    <Typography className='title' variant='h5'>{translate({id: `MISSION`})}</Typography>                            
                </div>
                <div className='info-section'>
                    {translate({id: `NIU_MISSION_TEXT`})}
                </div>
                <div className='title-container'>
                    <Typography className='title' variant='h5'>{translate({id: `VISION`})}</Typography>                            
                </div>
                <div className='info-section'>
                    {translate({id: `NIU_VISION_TEXT`})}
                </div>
            </div>
        </div>
    ))
}