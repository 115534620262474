import React from 'react'
import Box from '@material-ui/core/Box'
import ImageSliderView from '../ImageSliderView'
import SectionSpecifications from '../SectionSpecifications'
import FormBuyProduct from '../FormBuyProduct'
import { getAccessoryById } from '../../helpers/accessory'
import AccessoryInfoBox from '../AccessoryInfoBox'
import SelectorRegion from '../SelectorRegion'
import Image from '../Image'
import Title from '../Title'
import { loadTranslation } from '../../translation' 


import './style.css'

export default class PageModel extends React.Component {
    state = {
        region: 0, 
    }
    handleRegionChange = (value) => {
        this.setState({region: value})
    }
    render() {
        const { region } = this.state
        const accessory_id = this.props.match.params.accessory_id
        const accessoryInfo = getAccessoryById(accessory_id)
        const colors = ['white', 'blue', 'red', 'black', 'mate-gray', 'mate-black']
        const imageCount = 5
        const folderName = 'nqi-sl'
        
        var specificationIndex = 0
        // accessoryInfo.forEach((item, index) => {
        //     if(item.region === region) {
        //         specificationIndex = index
        //         return
        //     }
        // })
        return loadTranslation( translate => accessoryInfo.images.length > 0? (
            <div className='page-accessory content-container'>
                <Title pageTitle={translate({id: accessoryInfo.name})} siteTitle={`NIU ${translate({id: 'ARMENIA'})}`} />
                <ImageSliderView images={accessoryInfo.images} />
                <div className='content-body'>
                    <FormBuyProduct name={translate({id: accessoryInfo.name})} note={accessoryInfo.note? translate({id: accessoryInfo.note}):false} />
                </div>
                <Box pt={2} />
                <AccessoryInfoBox accessory={accessoryInfo} />
            </div>
        ):'No images for accessory.')
    }
}