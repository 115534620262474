import React, { useContext } from 'react'
import ProgressiveImage from 'react-progressive-image-loading'
import AppContext from '../../contexts/AppContext'
import { DEVICE_TYPES } from '../../helpers/device'
import Picture from './picture'

import './style.css'

function is_image_cached(src) {
    var img = new Image();
    img.src = src;

    return img.complete;
}

export default function ImageComponent(props) {
    const context = useContext(AppContext)
    var finalSource = false
    var imgSources = {}
    
    if(String(props.src).includes('http')) {
        imgSources = {
            desktop: props.src,
            mobile: props.mobileSrc? props.mobileSrc:props.src,
            lazy: props.lazySrc
        }
    } else {
        var simpleURL = `/img/${props.src}`
        imgSources = {
            desktop: !props.simple? `/img/desktop/${props.src}`:simpleURL,
            mobile: !props.simple? `/img/mobile/${props.src}`:simpleURL, // Change in production ,
            lazy:  !props.simple? `/img/lazy/${props.src}`:false, // Change in production !props.simple? `/img/lazy/${props.src}`:false,
        }
    }
    switch (context.currentDeviceType) {
        case DEVICE_TYPES.MOBILE:
        case DEVICE_TYPES.TABLET:
            finalSource = imgSources.mobile
            break;
        default:
            finalSource = imgSources.desktop
            break;
    }
    return (
        imgSources.lazy && !is_image_cached(finalSource)? <ProgressiveImage
            preview={imgSources.lazy}
            src={finalSource}
            transitionTime={400}
            transitionFunction="ease-in-out"
            render={(src, style) => {
                return <Picture className={props.className} src={src} style={style} />
            }}
        />:<Picture className={props.className} src={finalSource}/>
    )
}