export const TRADEMARK = {
    MQI_PLUS: 'trademarks/MQi+.png',
    MQI_PLUS_LITE: 'trademarks/MQi+-Lite.png',
    MQI_PLUS_PRO: 'trademarks/MQi+-Pro.png',
    MQI_PLUS_SPORT: 'trademarks/MQi+-Sport.png',
    MQI_GT: 'trademarks/MQi-GT.png',
    MQI_GT_CARGO: 'trademarks/MQi-GT-Pro.png',
    MQI_GT_SPORT: 'trademarks/MQi-GT-Sport.png',
    MQI_SERIES: 'trademarks/MQi-Series.png',
    NQI: 'trademarks/NQi.png',
    NQI_CARGO: 'trademarks/NQi-Cargo.png',
    NQI_GT: 'trademarks/NQi-GT.png',
    NQI_GT_PRO: 'trademarks/NQi-GT-Pro.png',
    NQI_GT_PRO_CARGO: 'trademarks/NQi-GT-Pro-Cargo.png',
    NQI_GTS: 'trademarks/NQi-GTS.png',
    NQI_GT_SPORT: 'trademarks/NQi-GT-Sport.png',
    NQI_GT_SPORT_CARGO: 'trademarks/NQi-GT-Sport-Cargo.png',
    NQI_GTS_PRO: 'trademarks/NQi-GTS-Pro.png',
    NQI_GTS_SPORT: 'trademarks/NQi-GTS-Sport.png',
    NQI_LITE: 'trademarks/NQi-Lite.png',
    NQI_PRO: 'trademarks/NQi-Pro.png',
    NQI_SERIES: 'trademarks/NQi-Series.png',
    NQI_SPORT: 'trademarks/NQi-Sport.png',
    UQI: 'trademarks/UQi.png',
    UQI_GT: 'trademarks/UQi-GT.png',
    UQI_GT_PRO: 'trademarks/UQi-GT-Pro.png',
    UQI_GT_SPORT: 'trademarks/UQi-GT-Sport.png',
    UQI: 'trademarks/UQim.png',
    UQIm_PRO: 'trademarks/UQim-Pro.png',
    UQI_SPORT: 'trademarks/UQim-Sport.png',
    UQI_PRO: 'trademarks/UQi-Pro.png',
    UQI_SERIES: 'trademarks/UQi-Series.png',
    KQI_PRO: 'trademarks/KQi-Series.png',
    KQI_SPORT: 'trademarks/KQi-Series.png',
    EUB_01_PRO: 'trademarks/AERO.png',
    EUB_01_SPORT: 'trademarks/AERO.png'
}