import React from 'react'
import Box from '@material-ui/core/Box'
import ModelView from '../ModelView'
import SectionSpecifications from '../SectionSpecifications'
import FormBuyProduct from '../FormBuyProduct'
import { getModelInfo, toModelName } from '../../helpers/model'
import SelectorRegion from '../SelectorRegion'
import Title from '../Title'
import { loadTranslation } from '../../translation' 

import './template.css'



export default class PageModel extends React.Component {
    state = {
        region: 0, 
    }
    handleRegionChange = (value) => {
        this.setState({region: value})
    }
    render() {
        const { region } = this.state
        const modelName = this.props.match.params.model_name
        const modelInfo = getModelInfo(modelName)
        var specificationIndex = 0
        modelInfo.forEach((item, index) => {
            if(item.region === region) {
                specificationIndex = index
                return
            }
        })

        return modelInfo.length > 0? loadTranslation( translate => (
            <div className='page-model content-container'>
                <Title pageTitle={modelInfo[0].model} siteTitle={`NIU ${translate({id: 'ARMENIA'})}`} />
                <ModelView modelName={toModelName(modelInfo[0].model)} modelImages={modelInfo[0].images} />
                <div className='content-body'>
                    <FormBuyProduct name={modelInfo[0].model} price={modelInfo[specificationIndex].price} selectors={[
                        <SelectorRegion key={1} value={region? region:modelInfo[0].region} onChange={this.handleRegionChange} regions={modelInfo.map(model => model.region)} />
                    ]} />
                </div>
                <Box pt={2} />
                <SectionSpecifications specifications={modelInfo[specificationIndex]} />
            </div>
        )):'No such model'
    }
}