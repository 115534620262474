import React from 'react'
import SliderLarge from '../SliderLarge'
import BannerLarge from '../BannerLarge'
import Trademark from '../Trademark'
import ImageCard from '../ImageCard'
import AnalyticsSection from '../AnalyticsSection'
import SectionTestDrive from '../SectionTestDrive'
import SectionNewConcepts from '../SectionNewConcepts'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { loadTranslation } from '../../translation'
import MODELS, { toModelName } from '../../helpers/model'
import { Link } from 'react-router-dom'
import Title from '../Title'

import './style.css'

export default class PageHome extends React.Component {
    render() {
        return loadTranslation( translate => {
            const sliderItems = [
                // <BannerLarge imgSrc={'/section/banner1.jpg'} title={<Trademark name='MQI_PLUS_SPORT' />} subtitle='' description='' />,
                <BannerLarge imgSrc={'/section/banner4.jpg'} title={<div className='main-title'>{translate({id: 'WORLD_NO1_SES'})}</div>}/>
            ]
            const links = []
            Object.keys(MODELS).forEach(serie => {
                MODELS[serie].models.forEach((model, index) => {
                    links.push(
                        <Link key={model.model+index} to={`/model/${toModelName(model.model)}`}>
                            <ListItem button>
                                <ListItemText primary={model.model} />
                            </ListItem>
                        </Link>
                    )
                })
            })

            return (
                <div className="page-home content-container">
                    <Title pageTitle={translate({id: 'HOME_PAGE'})} siteTitle={`NIU ${translate({id: 'ARMENIA'})}`} />
                    <SliderLarge items={sliderItems} />
                    {/* <Box pt={2} /> */}
                    <SectionTestDrive />
                    <Box pt={5} />
                    <div className="content-body">
                        <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <ImageCard href={'/series/U'} src={'/series/U.jpg'} title={translate({id: 'U_SERIES'})} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <ImageCard href={'/series/N'} src={'/series/N.jpg'} title={translate({id: 'N_SERIES'})} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <ImageCard href={'/series/M'} src={'/series/M.jpg'} title={translate({id: 'M_SERIES'})} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <ImageCard href={'/series/EUB'} src={'/series/eub.jpg'} title={translate({id: 'EUB_SERIES'})} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <ImageCard href={'/series/KQI'} src={'/series/kick_scooter.jpg'} title={translate({id: 'KICK_SCOOTER_SERIES'})} />
                                </Grid>
                        </Grid>
                    </div>
                    <Box pt={7} />
                    <AnalyticsSection />
                    <Box pt={4} />
                    <SectionNewConcepts />
                </div>
            )
        })
    }
}