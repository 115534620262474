import React from 'react'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import contacts from '../../helpers/contact'

import './style.css'
import contact from '../../helpers/contact';

export default class ShareBar extends React.Component {
    render() {
        return (
            <div className="share-bar">
                {
                    contact.officialGroups['facebook']? <a className='social-btn' target='_blank' href={contact.officialGroups['facebook']}><FacebookIcon className='icon' /></a>:false
                }
                {
                    contact.officialGroups['twitter']? <a className='social-btn' target='_blank' href={contact.officialGroups['twitter']}><TwitterIcon className='icon' /></a>:false
                }
                {
                    contact.officialGroups['instagram']? <a className='social-btn' target='_blank' href={contact.officialGroups['instagram']}><InstagramIcon className='icon' /></a>:false
                }
                {
                    contact.officialGroups['youtube']? <a className='social-btn' target='_blank' href={contact.officialGroups['youtube']}><YouTubeIcon className='icon' /></a>:false
                }
            </div>
        )
    }
}