import React from 'react'
import MobileStepper from '@material-ui/core/MobileStepper'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import './style.css'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default class SliderLarge extends React.Component {
    state = {
        activeStep: 0
    }

    setActiveStep = (value) => {
        this.setState({activeStep: value})
    }

    handleStepChange = (step) => {
        this.setActiveStep(step);
    }

    componentWillReceiveProps(newProps) {
        if (this.state.activeStep >= newProps.items.length) {
            this.setActiveStep(newProps.items.length? newProps.items.length - 1:0)
        }
    }

    render() {
        const { activeStep } = this.state
        return (
            <div className="slider-large-container">
                      <div className="slider-wrapper">
                        <AutoPlaySwipeableViews 
                                className="slider"
                                axis='x'
                                index={activeStep}
                                onChangeIndex={this.handleStepChange}
                                enableMouseEvents
                                
                            >
                                {Array.isArray(this.props.items)? this.props.items.map((item, index) => (
                                <div key={index}>
                                    {Math.abs(activeStep - index) <= 2 ? (
                                        item
                                    ) : null}
                                </div>
                                )):false}
                            </AutoPlaySwipeableViews>
                      </div>
                        <MobileStepper
                            className="stepper"
                            variant="progress"
                            steps={this.props.items? this.props.items.length:0}
                            position="static"
                            activeStep={activeStep}
                        />
            </div>
        )
    }
}