import React from 'react'
import Image from '../Image'
import SliderLarge from '../SliderLarge'
import Trademark from '../Trademark'
import ColorSelector from '../ColorSelector'

import './style.css'

export default class ModelView extends React.Component {
    render() {
        const { images } = this.props

        return (
            <div className="image-slider-view">
                <SliderLarge items={images.map(i => <Image key={i} className='single-img' src={i} noLazy={true}/>)} />
            </div>
        )
    }
}