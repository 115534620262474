import ACTIONS from '../actions'
import LOCALES from '../../translation/locales'

const defaultState = {
    locale: LOCALES.EN
}

export default function languageReducer(state=defaultState, action) {
    switch(action.type) {
        case ACTIONS.SET_APPLICATION_LANGUAGE:
            return {...state, locale: action.language}
        default:
            return {...state}
    }
}