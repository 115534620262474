import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ImageCard from '../ImageCard'
import SectionNewConcepts from '../SectionNewConcepts'
import Image from '../Image'
import Title from '../Title'
import { loadTranslation } from '../../translation' 
import { getModelsBySeries, getModelInfo, toModelName } from '../../helpers/model' 

import './style.css'

export default function PageSeries (props) {
    const BANNER_IMAGE_SRC = {
        'U': '/section/useries.jpg',
        'N': '/section/nseries.jpg',
        'M': '/section/mseries.jpg',
        'EUB': '/section/eub.jpg',
        'KQI': '/section/kqi.png'
    }
    return loadTranslation( translate => (
        <div className='page-series content-container'>
            <Title pageTitle={translate({id: `${props.match.params.series_name}_SERIES`})} siteTitle={`NIU ${translate({id: 'ARMENIA'})}`} />
            {BANNER_IMAGE_SRC[String(props.match.params.series_name).toUpperCase()]? <Image className='img-banner' src={BANNER_IMAGE_SRC[String(props.match.params.series_name).toUpperCase()]} />:false}
            <div className='content-body serie-title-container'>
                {
                    loadTranslation(translate => (<Typography className='series-name' variant='h4'>{translate({id: `${props.match.params.series_name}_SERIES`})}</Typography>))
                }
            </div>
            <div className='content-body'>
                <Grid container>
                    {
                        getModelsBySeries(props.match.params.series_name).map((item, index) => (
                            <Grid key={item.model + index} xl={4} lg={4} md={4} sm={6} xs={12} item>
                                <ImageCard className='model-item' href={`/model/${toModelName(item.model)}`} src={getModelInfo(toModelName(item.model))[0].images.main} title={item.model} />
                            </Grid>
                        ))
                    }
                    
                </Grid>
                <Box mt={7} />
                <SectionNewConcepts />
            </div>
        </div>
    ))
}