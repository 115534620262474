import React from 'react'
import ImageSection from '../ImageSection'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { loadTranslation } from '../../translation'

import './style.css'

export default function SectionTestDrive(props) {
    return loadTranslation( translate => (
        <ImageSection className='test-drive-section' src={'/section/test-drive.jpg'} title={translate({id: 'TEST_DRIVE'})}  footer={
            (<Link className='link-item' to={'/contacts'}><Button variant="contained" color="primary" disableElevation>
                {translate({id: 'CONTACT_US'})}
            </Button></Link>)}/>
    ))
}