import React from 'react';
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TranslationProvider } from '../../translation'
import { AppContextProvider } from '../../contexts/AppContext'
import { SET_APPLICATION_LANGUAGE_ACTION } from '../../redux/actions/languageActions'
import { getDeviceType } from '../../helpers/device';
import ScrollToTop from '../HookScrollToTop'

import Navbar from '../Navbar'
import PageHome from '../PageHome'
import PageContacts from '../PageContacts'
import PageModel from '../PageModel'
import PageSeries from '../PageSeries'
import PageAccessories from '../PageAccessories';
import PageAccessory from '../PageAccessory'
import PageAbout from '../PageAbout'
import Footer from '../Footer'

import './style.css'
import './scrollbar.css'

const CURRENT_DEVICE_TYPE = getDeviceType()

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  overrides: {
  },
});

theme.typography.h3 = {
  fontSize: '1.2rem',
  fontFamily: 'Montserrat',
  fontWeight: '400',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

function App(props) {
  const language = localStorage.getItem('language')? localStorage.getItem('language'):props.language.locale
  
  const contextValue = {
    currentDeviceType: CURRENT_DEVICE_TYPE,
    language: language,
    setApplicationLanguage: props.setApplicationLanguage,
  }
  return (
    <div className="App">
      <BrowserRouter>
        <AppContextProvider value={contextValue}>
            <TranslationProvider locale={language}>
              <ThemeProvider theme={theme}>
                <ScrollToTop />
                <Route path='/' render={(props) =>  <Navbar {...props} />} />
                <Switch>
                  <Route exact path='/' render={(props) => <PageHome {...props} />} />
                  <Route exact path='/contacts' render={(props) => <PageContacts {...props} />} />
                  <Route exact path='/about' render={(props) => <PageAbout {...props} />} />
                  <Route exact path='/series/:series_name' render={(props) => <PageSeries {...props} />} />
                  <Route exact path='/model/:model_name' render={(props) => <PageModel {...props} />} />
                  <Route exact path='/accessories' render={(props) => <PageAccessories {...props} />} />
                  <Route exact path='/accessories/:page' render={(props) => <PageAccessories {...props} />} />
                  <Route exact path='/accessory/:accessory_id' render={(props) => <PageAccessory {...props} />} />
                  <Route  render={(props) => <PageHome {...props} />} />
                </Switch>
                <Footer />
              </ThemeProvider>
            </TranslationProvider>
        </AppContextProvider>
      </BrowserRouter>
    </div>
  );
}


const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setApplicationLanguage: language => dispatch(SET_APPLICATION_LANGUAGE_ACTION(language))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);