const locales = {
    EN: 'en',
    RU: 'ru',
    AM: 'am'
}

export default locales

export const languages = [
    {
        name: 'English',
        value: locales.EN
    },
    {
        name: 'Русский',
        value: locales.RU
    },
    {
        name: 'Հայերեն',
        value: locales.AM
    }
]