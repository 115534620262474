import React from 'react'
import SliderLarge from '../SliderLarge'
import ImageInfoBox from '../ImageInfoBox'
import { loadTranslation } from '../../translation'

import './style.css'

export default function SectionNewConcepts(props) {
    return loadTranslation (translate => (
        <div className='content-body section-coming-soon'>
            <SliderLarge items={[
                    <ImageInfoBox className='banner-item' src={'/models/rqi/gray/1.jpg'} title={translate({id: 'CONCEPT_RQI_SERIES_TITLE'})} description={translate({id: 'CONCEPT_RQI_SERIES_DESCRIPTION'})} label={translate({id: 'CONCEPT'})} url='https://www.youtube.com/watch?v=JeCzr1FBHm4' />,
                    <ImageInfoBox className='banner-item' src={'/models/tqi/gray/1.jpg'} title={translate({id: 'CONCEPT_TQI_SERIES_TITLE'})} description={translate({id: 'CONCEPT_TQI_SERIES_DESCRIPTION'})} label={translate({id: 'CONCEPT'})} url='https://www.youtube.com/watch?v=chLMfeRIwYs' />,
                ]} />
        </div>
    ))
}