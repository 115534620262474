import React from 'react'
import Image from '../Image'
import SliderLarge from '../SliderLarge'
import Trademark from '../Trademark'
import ColorSelector from '../ColorSelector'

import './style.css'

const generateModelImageUrls = (modelImages, color) => {
    const modelImageUrls = []
    if (modelImages) {
        for(var i = 1; i <= modelImages.colors[color]; i++)
        {
            modelImageUrls.push(`models/${modelImages.folder}/${color}/${i}.jpg`)
        }
    }    
    return modelImageUrls
}
export default class ModelView extends React.Component {
    state = {
        color: false
    }
    changeColor = (color) => {
        this.setState({color: color})
    }
    render() {
        const { modelImages, modelName } = this.props
        const { color } = this.state

        var realColor = false
        if (color) {
            realColor = color
        } else if(Object.keys(modelImages).length > 0) {
            realColor = Object.keys(modelImages.colors)[0]
        } else {
            return false
        }

        const images = generateModelImageUrls(modelImages, realColor).map((imgUrl, index) => <Image key={imgUrl} className='model-img' src={imgUrl} noLazy={true}/>)
        return (
            <div className="model-view content-container">
                <div className="model-view-inner">
                    <SliderLarge items={images} />
                    <div className='data-block content-body'>
                        <div className='model-name'><Trademark name={modelName} /></div>
                        <div className='model-subtitle'></div>
                    </div>
                    <div className='control-block'>
                        <ColorSelector activeColor={realColor} colors={Object.keys(modelImages.colors)} onClick={(color) => this.changeColor(color)} />
                    </div>
                </div>
            </div>
        )
    }
}