import React from 'react'
import AppLogo from '../AppLogo'
import ShareBar from '../ShareBar'
import './style.css'

export default class Footer extends React.Component {
    render() {
        return(
            <div className="app-footer">
                <div className="logo-container"><AppLogo className='logo' /></div>
                <div className="share-btns"><ShareBar /></div>
                <div className="copyright">© 2020 Niu International.All rights reserved.</div>
            </div>
        )
    }
}