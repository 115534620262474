import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Image from '../Image'
import axios from 'axios'
import { loadTranslation } from '../../translation'
import './style.css'

export default class AnalyticsSection extends React.Component {
    state = {
        analyticsData: {}
    }
    componentDidMount() {
        axios.get(`/data/analytics.json`)
        .then(res => {
            const analytics = res.data;
            this.setState({ analyticsData: analytics });
        })
    }

    render() {
        const { analyticsData } = this.state
        const dataKeys = [
            {
                key: 'totalmileage',
                note: 'TOTALMILEAGE',
                measure: 'KM',
                icon: 'https://s.niucache.com/www/niu-i18n/img/8302e99.svg'
            },
            {
                key: 'cycling_count',
                note: 'CYCLING_COUNT',
                icon: 'https://s.niucache.com/www/niu-i18n/img/5de15df.svg'
            },
            {
                key: 'carbon',
                note: 'CARBON',
                measure: 'KG',
                icon: 'https://s.niucache.com/www/niu-i18n/img/52d664b.svg'
            },
            {
                key: 'plant_trees',
                note: 'PLANT_TREES',
                icon: 'https://s.niucache.com/www/niu-i18n/img/d3aec47.svg'
            },
            
        ]
        return loadTranslation( translate => (
            <div className='analytics-section content-body'>
                <Typography className='section-title' variant='h2'>{translate({id: 'NIU_INSPIRE'})}</Typography>
                <Grid container>
                    {
                        dataKeys.map(elem => (
                            <Grid key={elem.key} className='analytics-item' xs={12} md={6} item>
                                <Grid container>
                                    <Grid className='icon-container' xs={4} sm={5} md={4} item><Image className='icon' src={elem.icon}/></Grid>
                                    <Grid className='info-container' xs={8} sm={7} md={8} item>
                                        <div className='info-inner'>
                                            <div className='value-container'>
                                                <div className='value'>
                                                    <Typography variant='h4'>
                                                        {
                                                            parseInt(String(analyticsData[elem.key])).toLocaleString()
                                                        }
                                                    </Typography>
                                                    <div className='measure'>{elem.measure}</div>
                                                </div>
                                            </div>
                                            <div className='note'><Typography variant='subtitle1'>{translate({id: elem.note})}</Typography></div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        ))
    }
}