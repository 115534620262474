const generateImageURLs = (name, count) => {
    const folderName = String(name)
    return [...Array(count).keys()].map(i => `/accessories/${folderName}/image${i}.png`)
}

const accessories = [
    {
        id: 1,
        name: 'ACCESSORY_1_NAME',
        note: 'ACCESSORY_1_NOTE',
        images: generateImageURLs(1, 6),
        mainImage: '/accessories/1/image0.png',
        spec: {
            dimension: {
                length: 365,
                width: 328,
                height: 238,
                measure: 'MEASURE_MILIMETER'
            }
        },
        compatibility: ['NQi Series']
    },
    {
        id: 2,
        name: 'ACCESSORY_2_NAME',
        images: generateImageURLs(2, 6),
        mainImage: '/accessories/2/image0.png',
        compatibility: ['NQi Series']
    },
    {
        id: 3,
        name: 'ACCESSORY_3_NAME',
        images: generateImageURLs(3, 1),
        mainImage: '/accessories/3/image0.png',
        compatibility: ['NQi Series']
    },
    {
        id: 4,
        name: 'ACCESSORY_4_NAME',
        images: generateImageURLs(4, 1),
        mainImage: '/accessories/4/image0.png',
        spec: {
            dimension: {
                length: 760,
                width: 720,
                height: 158,
                measure: 'MEASURE_MILIMETER'
            },
            weight: {
                value: 1.5,
                measure: 'MEASURE_KILOGRAM'
            }

        },
        compatibility: ['NQi Series']
    },
    {
        id: 5,
        name: 'ACCESSORY_5_NAME',
        images: generateImageURLs(5, 4),
        mainImage: '/accessories/5/image0.png',
        compatibility: ['MQi Series']
    },
    {
        id: 6,
        name: 'ACCESSORY_6_NAME',
        images: generateImageURLs(6, 1),
        mainImage: '/accessories/6/image0.png',
        compatibility: ['MQi Series']
    },
    {
        id: 7,
        name: 'ACCESSORY_7_NAME',
        images: generateImageURLs(7, 1),
        mainImage: '/accessories/7/image0.png',
        compatibility: ['MQi+ Series']
    },
    {
        id: 8,
        name: 'ACCESSORY_8_NAME',
        images: generateImageURLs(8, 1),
        mainImage: '/accessories/8/image0.png',
        compatibility: ['MQi Series']
    },
    {
        id: 9,
        name: 'ACCESSORY_9_NAME',
        images: generateImageURLs(9, 1),
        mainImage: '/accessories/9/image0.png',
        compatibility: ['UQi Pro']
    },
    {
        id: 10,
        name: 'ACCESSORY_10_NAME',
        images: generateImageURLs(10, 1),
        mainImage: '/accessories/10/image0.png',
        compatibility: ['UQi Pro']
    },
    {
        id: 11,
        name: 'ACCESSORY_11_NAME',
        images: generateImageURLs(11, 1),
        mainImage: '/accessories/11/image0.png',
        compatibility: ['UQi Pro', 'UQi GT']
    },
    {
        id: 12,
        name: 'ACCESSORY_12_NAME',
        note: 'ACCESSORY_12_NOTE',
        images: generateImageURLs(12, 1),
        mainImage: '/accessories/12/image0.png',
        compatibility: ['UQi Pro', 'UQi GT'],
        compatibilityRequirement: 'ACCESSORY_12_CR'
    },
    {
        id: 13,
        name: 'ACCESSORY_13_NAME',
        note: 'ACCESSORY_13_NOTE',
        images: generateImageURLs(13, 11),
        mainImage: '/accessories/13/image0.png',
        compatibility: ['UQi Pro', 'UQi GT'],
        compatibilityRequirement: 'ACCESSORY_13_CR'
    },
    {
        id: 14,
        name: 'ACCESSORY_14_NAME',
        note: 'ACCESSORY_14_NOTE',
        images: generateImageURLs(14, 7),
        mainImage: '/accessories/14/image0.png',
        spec: {
            dimension: {
                length: 365,
                width: 328,
                height: 238,
                measure: 'MEASURE_MILIMETER'
            },
            capacity: {
                value: 14,
                measure: 'MEASURE_LITRE'
            }
        },
        compatibility: ['UQi Pro', 'UQi GT'],
        compatibilityRequirement: 'ACCESSORY_14_CR'
    },
    {
        id: 15,
        name: 'ACCESSORY_15_NAME',
        images: generateImageURLs(15, 1),
        mainImage: '/accessories/15/image0.png',
        compatibility: ['UQi Pro'],
    },
    {
        id: 16,
        name: 'ACCESSORY_16_NAME',
        images: generateImageURLs(16, 1),
        mainImage: '/accessories/16/image0.png',
        spec: {
            maxLoad: {
                value: 5,
                measure: 'MEASURE_KILOGRAM'
            }
        },
        compatibility: ['MQi+ Series'],
    },
    {
        id: 17,
        name: 'ACCESSORY_17_NAME',
        images: generateImageURLs(17, 5),
        mainImage: '/accessories/17/image0.png',
        compatibility: ['MQi+ Series'],
    },
    {
        id: 18,
        name: 'ACCESSORY_18_NAME',
        note: 'ACCESSORY_18_NOTE',
        images: generateImageURLs(18, 5),
        mainImage: '/accessories/18/image0.png',
        spec: {
            dimension: {
                length: 365,
                width: 328,
                height: 238,
                measure: 'MEASURE_MILIMETER'
            },
            capacity: {
                value: 14,
                measure: 'MEASURE_LITRE'
            }
        },
        compatibility: ['MQi Series'],
    },
    {
        id: 19,
        name: 'ACCESSORY_19_NAME',
        images: generateImageURLs(19, 1),
        mainImage: '/accessories/19/image0.png',
        compatibility: ['MQi Series'],
    },
    {
        id: 20,
        name: 'ACCESSORY_20_NAME',
        images: generateImageURLs(20, 1),
        mainImage: '/accessories/20/image0.png',
        spec: {
            dimension: {
                width: 310,
                height: 220,
                measure: 'MEASURE_MILIMETER'
            }
        },
        compatibility: ['MQi Series', 'MQi+ Series'],
    },
    {
        id: 21,
        name: 'ACCESSORY_21_NAME',
        images: generateImageURLs(21, 1),
        mainImage: '/accessories/21/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 22,
        name: 'ACCESSORY_22_NAME',
        images: generateImageURLs(22, 1),
        mainImage: '/accessories/22/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 23,
        name: 'ACCESSORY_23_NAME',
        note: 'ACCESSORY_23_NOTE',
        images: generateImageURLs(23, 1),
        mainImage: '/accessories/23/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 24,
        name: 'ACCESSORY_24_NAME',
        images: generateImageURLs(24, 1),
        mainImage: '/accessories/24/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 25,
        name: 'ACCESSORY_25_NAME',
        images: generateImageURLs(25, 1),
        mainImage: '/accessories/25/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 26,
        name: 'ACCESSORY_26_NAME',
        images: generateImageURLs(26, 1),
        mainImage: '/accessories/26/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 27,
        name: 'ACCESSORY_27_NAME',
        images: generateImageURLs(27, 1),
        mainImage: '/accessories/27/image0.png',
        compatibility: ['MQi Series', 'MQi+ Series'],
    },
    {
        id: 28,
        name: 'ACCESSORY_28_NAME',
        images: generateImageURLs(28, 1),
        mainImage: '/accessories/28/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 29,
        name: 'ACCESSORY_29_NAME',
        images: generateImageURLs(29, 1),
        mainImage: '/accessories/29/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 0,
        name: 'ACCESSORY_0_NAME',
        images: generateImageURLs(29, 1),
        mainImage: '/accessories/29/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 30,
        name: 'ACCESSORY_30_NAME',
        images: generateImageURLs(30, 1),
        mainImage: '/accessories/30/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 31,
        name: 'ACCESSORY_31_NAME',
        images: generateImageURLs(31, 1),
        mainImage: '/accessories/31/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 32,
        name: 'ACCESSORY_32_NAME',
        images: generateImageURLs(32, 1),
        mainImage: '/accessories/32/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 33,
        name: 'ACCESSORY_33_NAME',
        images: generateImageURLs(33, 1),
        mainImage: '/accessories/33/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 34,
        name: 'ACCESSORY_34_NAME',
        images: generateImageURLs(34, 1),
        mainImage: '/accessories/34/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
    {
        id: 35,
        name: 'ACCESSORY_35_NAME',
        images: generateImageURLs(35, 1),
        mainImage: '/accessories/35/image0.png',
    },
    {
        id: 36,
        name: 'ACCESSORY_36_NAME',
        images: generateImageURLs(36, 1),
        mainImage: '/accessories/36/image0.png',
    },
    {
        id: 37,
        name: 'ACCESSORY_37_NAME',
        images: generateImageURLs(37, 1),
        mainImage: '/accessories/37/image0.png',
    },
    {
        id: 38,
        name: 'ACCESSORY_38_NAME',
        images: generateImageURLs(38, 1),
        mainImage: '/accessories/38/image0.png',
    },
    {
        id: 39,
        name: 'ACCESSORY_39_NAME',
        spec: {
            modelScale: '1:12'
        },
        images: generateImageURLs(39, 1),
        mainImage: '/accessories/39/image0.png',
    },
    {
        id: 40,
        name: 'ACCESSORY_40_NAME',
        images: generateImageURLs(40, 4),
        mainImage: '/accessories/40/image0.png',
    },
    {
        id: 41,
        name: 'ACCESSORY_41_NAME',
        note: 'ACCESSORY_41_NOTE',
        spec: {
            sizes: ['XXL', 'XL', 'L', 'M', 'S']
        },
        images: generateImageURLs(41, 1),
        mainImage: '/accessories/41/image0.png',
    },
    {
        id: 42,
        name: 'ACCESSORY_42_NAME',
        note: 'Logo: #NIU POWER',
        images: generateImageURLs(42, 1),
        mainImage: '/accessories/42/image0.png',
    },
    {
        id: 43,
        name: 'ACCESSORY_43_NAME',
        images: generateImageURLs(43, 2),
        mainImage: '/accessories/43/image0.png',
    },
    {
        id: 44,
        name: 'ACCESSORY_44_NAME',
        images: generateImageURLs(44, 2),
        mainImage: '/accessories/44/image0.png',
    },
    {
        id: 45,
        name: 'ACCESSORY_45_NAME',
        images: generateImageURLs(45, 2),
        mainImage: '/accessories/45/image0.png',
    },
    {
        id: 46,
        name: 'ACCESSORY_46_NAME',
        images: generateImageURLs(46, 2),
        mainImage: '/accessories/46/image0.png',
    },
    {
        id: 47,
        name: 'ACCESSORY_47_NAME',
        spec: {
            sizes: ['XXXL', 'XXL', 'XL', 'L', 'M']
        },
        images: generateImageURLs(47, 1),
        mainImage: '/accessories/47/image0.png',
    },
    {
        id: 48,
        name: 'ACCESSORY_48_NAME',
        spec: {
            sizes: ['XL', 'L', 'M', 'S']
        },
        images: generateImageURLs(48, 1),
        mainImage: '/accessories/48/image0.png',
    },
    {
        id: 49,
        name: 'ACCESSORY_49_NAME',
        spec: {
            sizes: ['XL', 'L', 'M', 'S']
        },
        images: generateImageURLs(49, 1),
        mainImage: '/accessories/49/image0.png',
    },
    {
        id: 50,
        name: 'ACCESSORY_50_NAME',
        spec: {
            sizes: ['XL', 'L', 'M', 'S']
        },
        images: generateImageURLs(50, 1),
        mainImage: '/accessories/50/image0.png',
    },
    {
        id: 51,
        name: 'ACCESSORY_51_NAME',
        spec: {
            sizes: ['XL', 'L', 'M', 'S']
        },
        images: generateImageURLs(51, 1),
        mainImage: '/accessories/51/image0.png',
    },
    {
        id: 52,
        name: 'ACCESSORY_52_NAME',
        spec: {
            sizes: ['XL', 'L', 'M', 'S']
        },
        images: generateImageURLs(52, 1),
        mainImage: '/accessories/52/image0.png',
    },
    {
        id: 53,
        name: 'ACCESSORY_53_NAME',
        spec: {
            sizes: ['XL', 'L', 'M', 'S']
        },
        images: generateImageURLs(53, 1),
        mainImage: '/accessories/53/image0.png',
    },
    {
        id: 54,
        name: 'ACCESSORY_54_NAME',
        spec: {
            sizes: ['XL', 'L', 'M', 'S']
        },
        images: generateImageURLs(54, 1),
        mainImage: '/accessories/54/image0.png',
    },
    {
        id: 55,
        name: 'ACCESSORY_55_NAME',
        spec: {
            sizes: ['XL', 'L', 'M', 'S']
        },
        images: generateImageURLs(55, 1),
        mainImage: '/accessories/55/image0.png',
    },
    {
        id: 56,
        name: 'ACCESSORY_56_NAME',
        spec: {
            sizes: ['XL', 'L', 'M', 'S']
        },
        images: generateImageURLs(56, 1),
        mainImage: '/accessories/56/image0.png',
    },
    {
        id: 57,
        name: 'ACCESSORY_57_NAME',
        spec: {
            sizes: ['XL', 'L', 'M', 'S']
        },
        images: generateImageURLs(57, 1),
        mainImage: '/accessories/57/image0.png',
    },
    {
        id: 58,
        name: 'ACCESSORY_58_NAME',
        images: generateImageURLs(58, 2),
        mainImage: '/accessories/58/image0.png',
    },
    {
        id: 59,
        name: 'ACCESSORY_59_NAME',
        images: generateImageURLs(59, 2),
        mainImage: '/accessories/59/image0.png',
    },
    {
        id: 60,
        name: 'ACCESSORY_60_NAME',
        images: generateImageURLs(60, 2),
        mainImage: '/accessories/60/image0.png',
    },
    {
        id: 61,
        name: 'ACCESSORY_61_NAME',
        images: generateImageURLs(61, 2),
        mainImage: '/accessories/61/image0.png',
    },
    {
        id: 62,
        name: 'ACCESSORY_62_NAME',
        images: generateImageURLs(62, 1),
        mainImage: '/accessories/62/image0.png',
    },
    {
        id: 63,
        name: 'ACCESSORY_63_NAME',
        images: generateImageURLs(63, 1),
        mainImage: '/accessories/63/image0.png',
    },
    {
        id: 64,
        name: 'ACCESSORY_64_NAME',
        images: generateImageURLs(64, 1),
        mainImage: '/accessories/64/image0.png',
        spec: {
            dimension: {
                length: 442,
                width: 442,
                height: 328,
                measure: 'MEASURE_MILIMETER'
            },
            capacity: {
                value: 29,
                measure: 'MEASURE_LITRE'
            }
        },
        compatibility: ['NQi Series', 'MQi+ Series'],
        compatibilityRequirement: 'must be installed with rear bracket 5N1HB603J or 511HB617J'
    },
    {
        id: 65,
        name: 'ACCESSORY_65_NAME',
        images: generateImageURLs(65, 1),
        mainImage: '/accessories/65/image0.png',
        spec: {
            dimension: {
                length: 442,
                width: 442,
                height: 328,
                measure: 'MEASURE_MILIMETER'
            },
            capacity: {
                value: 29,
                measure: 'MEASURE_LITRE'
            }
        },
        compatibility: ['NQi Series'],
        compatibilityRequirement: 'must be installed with rear brackets 5N1HB603J or 511HB617J'
    },
    {
        id: 66,
        name: 'ACCESSORY_66_NAME',
        note: 'ACCESSORY_66_NOTE',
        images: generateImageURLs(66, 1),
        mainImage: '/accessories/66/image0.png',
        spec: {
            dimension: {
                length: 442,
                width: 442,
                height: 328,
                measure: 'MEASURE_MILIMETER'
            },
            capacity: {
                value: 29,
                measure: 'MEASURE_LITRE'
            }
        },
        compatibility: ['NQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
        compatibilityRequirement: 'ACCESSORY_66_CR'
    },
    {
        id: 67,
        name: 'ACCESSORY_67_NAME',
        note: 'ACCESSORY_67_NOTE',
        images: generateImageURLs(67, 1),
        mainImage: '/accessories/67/image0.png',
        spec: {
            dimension: {
                length: 442,
                width: 442,
                height: 328,
                measure: 'MEASURE_MILIMETER'
            },
            capacity: {
                value: 29,
                measure: 'MEASURE_LITRE'
            }
        },
        compatibility: ['NQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
        compatibilityRequirement: 'ACCESSORY_67_CR'
    },
    {
        id: 68,
        name: 'ACCESSORY_68_NAME',
        note: 'ACCESSORY_68_NOTE',
        images: generateImageURLs(68, 1),
        mainImage: '/accessories/68/image0.png',
        spec: {
            dimension: {
                length: 442,
                width: 442,
                height: 328,
                measure: 'MEASURE_MILIMETER'
            },
            capacity: {
                value: 29,
                measure: 'MEASURE_LITRE'
            }
        },
        compatibility: ['NQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
        compatibilityRequirement: 'ACCESSORY_68_CR'
    },
    {
        id: 69,
        name: 'ACCESSORY_69_NAME',
        note: 'ACCESSORY_69_NOTE',
        images: generateImageURLs(69, 1),
        mainImage: '/accessories/69/image0.png',
        spec: {
            dimension: {
                length: 442,
                width: 442,
                height: 328,
                measure: 'MEASURE_MILIMETER'
            },
            capacity: {
                value: 29,
                measure: 'MEASURE_LITRE'
            }
        },
        compatibility: ['MQi+ Series'],
        compatibilityRequirement: 'ACCESSORY_69_CR'
    },
    {
        id: 70,
        name: 'ACCESSORY_70_NAME',
        images: generateImageURLs(70, 1),
        mainImage: '/accessories/70/image0.png',
        spec: {
            dimension: {
                length: 442,
                width: 442,
                height: 328,
                measure: 'MEASURE_MILIMETER'
            },
            capacity: {
                value: 29,
                measure: 'MEASURE_LITRE'
            }
        },
        compatibility: ['MQi+ Series'],
        compatibilityRequirement: 'ACCESSORY_70_CR'
    },
    {
        id: 71,
        name: 'ACCESSORY_71_NAME',
        images: generateImageURLs(71, 1),
        mainImage: '/accessories/71/image0.png',
        compatibility: ['NQi Series'],
    },
    {
        id: 73,
        name: 'ACCESSORY_73_NAME',
        images: generateImageURLs(73, 1),
        mainImage: '/accessories/73/image0.png',
        compatibility: ['MQi+ Series'],
    },
    {
        id: 74,
        name: 'ACCESSORY_74_NAME',
        images: generateImageURLs(74, 1),
        mainImage: '/accessories/74/image0.png',
    },
    {
        id: 75,
        name: 'ACCESSORY_75_NAME',
        images: generateImageURLs(75, 1),
        mainImage: '/accessories/75/image0.png',
    },
    {
        id: 76,
        name: 'ACCESSORY_76_NAME',
        images: generateImageURLs(76, 1),
        mainImage: '/accessories/76/image0.png',
    },
    {
        id: 77,
        name: 'ACCESSORY_77_NAME',
        images: generateImageURLs(77, 1),
        mainImage: '/accessories/77/image0.png',
    },
    {
        id: 78,
        name: 'ACCESSORY_78_NAME',
        images: generateImageURLs(78, 1),
        mainImage: '/accessories/78/image0.png',
    },
    {
        id: 79,
        name: 'ACCESSORY_79_NAME',
        images: generateImageURLs(79, 1),
        mainImage: '/accessories/79/image0.png',
    },
    {
        id: 80,
        name: 'ACCESSORY_80_NAME',
        images: generateImageURLs(80, 1),
        mainImage: '/accessories/80/image0.png',
    },
    {
        id: 81,
        name: 'ACCESSORY_81_NAME',
        images: generateImageURLs(81, 1),
        mainImage: '/accessories/81/image0.png',
    },
    {
        id: 82,
        name: 'ACCESSORY_82_NAME',
        images: generateImageURLs(82, 1),
        mainImage: '/accessories/82/image0.png',
        spec: {
            clothesSize: {
                frontLength: {
                    value: 130,
                    measure: 'MEASURE_SANTIMETER'
                },
                backLength: {
                    value: 94,
                    measure: 'MEASURE_SANTIMETER'
                },
                width: {
                    value: 197,
                    measure: 'MEASURE_SANTIMETER'
                }
            },
            weight: {
                value: 370,
                measure: 'MEASURE_GRAMM'
            },
            material:  {
                value: 'Polyester',
                percent: '100%'
            }
        }
    },
    {
        id: 83,
        name: 'ACCESSORY_83_NAME',
        images: generateImageURLs(83, 1),
        mainImage: '/accessories/83/image0.png',
        spec: {
            clothesSize: {
                frontLength: {
                    value: 137,
                    measure: 'MEASURE_SANTIMETER'
                },
                backLength: {
                    value: 98,
                    measure: 'MEASURE_SANTIMETER'
                },
                width: {
                    value: 200,
                    measure: 'MEASURE_SANTIMETER'
                }
            },
            weight: {
                value: 400,
                measure: 'MEASURE_GRAMM'
            },
            material:  {
                value: 'Polyester',
                percent: '100%'
            }
        }
    },
    {
        id: 84,
        name: 'ACCESSORY_84_NAME',
        images: generateImageURLs(84, 1),
        mainImage: '/accessories/84/image0.png',
        compatibility: ['NQi Series', 'MQi Series', 'MQi+ Series', 'UQi Pro', 'UQi GT'],
    },
]

export const getAccessories = (limit, index) => {
    var start = index*limit
    var end = (index + 1)*limit

    if (end > accessories.length) {
        end = accessories.length
    }

    if (start >= accessories.length) {
        start = 0
        end = limit
    }

    return accessories.slice(start, end)
}

export const getAccessoryById = (id) => {
    for (var x = 0; x < accessories.length; x++) {
        if (accessories[x].id == id) {
            return accessories[x]
        }
    }
    return false
} 