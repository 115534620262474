import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { loadTranslation } from '../../translation'
import './style.css'

function extractSpecs(accessoryInfo, translate) {
    var info = []
    if (!accessoryInfo.spec) {
        return info
    }
    if (Object.keys(accessoryInfo.spec).includes('dimension')) {
        var dimensionList = [accessoryInfo.spec.dimension.length, accessoryInfo.spec.dimension.width, accessoryInfo.spec.dimension.height]

        info.push({
            label: 'DIMENSION',
            value: `${dimensionList.filter(i => i? true:false).join('*')} ${translate({id: accessoryInfo.spec.dimension.measure})}`
        })
    }
    if (Object.keys(accessoryInfo.spec).includes('weight')) {
        info.push({
            label: 'WEIGHT',
            value: `${accessoryInfo.spec.weight.value} ${translate({id: accessoryInfo.spec.weight.measure})}`
        })
    }
    if (Object.keys(accessoryInfo.spec).includes('capacity')) {
        info.push({
            label: 'CAPACITY',
            value: `${accessoryInfo.spec.capacity.value} ${translate({id: accessoryInfo.spec.capacity.measure})}`
        })
    }
    if (Object.keys(accessoryInfo.spec).includes('maxLoad')) {
        info.push({
            label: 'MAX_LOAD',
            value: `${accessoryInfo.spec.maxLoad.value} ${translate({id: accessoryInfo.spec.maxLoad.measure})}`
        })
    }
    if (Object.keys(accessoryInfo.spec).includes('modelScale')) {
        info.push({
            label: 'MODEL_SCALE',
            value: `${accessoryInfo.spec.modelScale}`
        })
    }
    if (Object.keys(accessoryInfo.spec).includes('sizes')) {
        info.push({
            label: 'SIZES',
            value: `${accessoryInfo.spec.sizes.join(', ')}`
        })
    }
    if (Object.keys(accessoryInfo.spec).includes('clothesSize')) {
        info.push({
            label: 'FRONT_LENGTH',
            value: `${accessoryInfo.spec.frontLength.value} ${translate({id: accessoryInfo.spec.frontLength.measure})}`
        })
        info.push({
            label: 'BACK_LENGTH',
            value: `${accessoryInfo.spec.backLength.value} ${translate({id: accessoryInfo.spec.backLength.measure})}`
        })
        info.push({
            label: 'WIDTH',
            value: `${accessoryInfo.spec.width.value} ${translate({id: accessoryInfo.spec.width.measure})}`
        })
    }
    if (Object.keys(accessoryInfo).includes('material')) {
        info.push({
            label: 'MATERIAL',
            value: `${accessoryInfo.spec.material.value} (${accessoryInfo.spec.material.percent})`
        })
    }
    return info
}

function extractCompatibility(accessoryInfo) {
    return {
        series: accessoryInfo.compatibility,
        requirement: accessoryInfo.compatibilityRequirement
    }
}

export default function AccessoryInfoBox(props) {
    const compatibility = extractCompatibility(props.accessory)

    return loadTranslation( translate => (
        <div className='accessory-info-box content-body'>
            {
                extractSpecs(props.accessory, translate).length? <div className='title'><Typography variant='h5'>{translate({id: 'SPECIFICATIONS'})}</Typography></div>:false
            }
            {
                extractSpecs(props.accessory, translate).length? (
                    <Grid className='spec-section' container>
                        <Grid xl={6} lg={6} md={6} xs={12} item>
                            <TableContainer component={Paper}>
                                <Table className='spec-table'>
                                    <TableBody>
                                    {extractSpecs(props.accessory, translate).map((row) => (
                                        <TableRow key={row.label}>
                                        <TableCell component="th" scope="row" className='table-cell cell-label'>
                                            {translate({id: row.label})}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='table-cell cell-value'>
                                            {row.value}
                                        </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                ):false
            }
            {
                compatibility.series && compatibility.series.length? (
                    <>
                    <div className='title compatibility-title'><Typography variant='h5'>{translate({id: 'COMPATIBILITY'})}</Typography></div>
                    <div className='compatibility-note'><Typography variant='h6'>{compatibility.requirement? translate({id: compatibility.requirement}):''}</Typography></div>
                    <Grid className='compatibility-container' container>
                        {
                            compatibility.series? compatibility.series.map(serie => (<Grid key={serie} xl={2} lg={2} md={2} sm={6} xs={6} className='serie-box' item><Typography className='serie-name' variant='subtitle2'>{serie}</Typography></Grid>)):false
                        }
                    </Grid>
                    </>
                ):false
            }
            </div>
    ))
}