import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ImageCard from '../ImageCard';
import Grid from '@material-ui/core/Grid'
import { loadTranslation } from '../../translation'

import './style.css'

export default function MenuListComposition(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={`series-menu-root`}>
        <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClose}
            >
      <div className={props.className}>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          variant="text"
          className="link-button"
        >
         {props.label}
        </Button>
        <div className='series-menu-inner' style={{display: open? 'inline-block':'none'}}>
            {
                loadTranslation(translate => (
                    <Grid container>
                        <Grid xs={4} onClick={handleClose} item><ImageCard className='grid-item' href={'/series/U'} src={'/series/U.jpg'} title={translate({id: 'U_SERIES'})} /></Grid>
                        <Grid xs={4} onClick={handleClose} item><ImageCard className='grid-item' href={'/series/N'} src={'/series/N.jpg'} title={translate({id: 'N_SERIES'})} /></Grid>
                        <Grid xs={4} onClick={handleClose} item><ImageCard className='grid-item' href={'/series/M'} src={'/series/M.jpg'} title={translate({id: 'M_SERIES'})}/></Grid>
                        <Grid xs={6} onClick={handleClose} item><ImageCard className='grid-item' href={'/series/EUB'} src={'/series/eub.jpg'} title={translate({id: 'EUB_SERIES'})}/></Grid>
                        <Grid xs={6} onClick={handleClose} item><ImageCard className='grid-item' href={'/series/KQI'} src={'/series/kick_scooter.jpg'} title={translate({id: 'KICK_SCOOTER_SERIES'})} /></Grid>
                    </Grid>
                ))
            }
        </div>
      </div>
      </ClickAwayListener>
    </div>
  );
}