import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';

import './style.css'

export default function LinkButton(props) {
    return (props.href? (
                    <Link to={props.href} className='link-btn' onClick={props.onClick}>
                        <Button variant="text" onClick={props.onClick} className={`link-button${props.className? ' ' + props.className:''}`} endIcon={props.endIcon}>
                            {
                                props.children
                            }
                        </Button>
                    </Link>
                ):(
                    <Button variant="text" onClick={props.onClick} className={`link-button${props.className? ' ' + props.className:''}`} endIcon={props.endIcon}>
                        {
                            props.children
                        }
                    </Button>))
}