import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CountInput from '../CountInput'
import { loadTranslation } from '../../translation'
import { intToCurrencyString } from '../../helpers/convertor'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import contacts from '../../helpers/contact'

import './style.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

export default class FormBuyProduct extends React.Component {
    state = {
        count: 1,
        buyDialogOpen: false,
    }
    maxCount = this.props.maxCount? this.props.maxCount:10
    minCount = this.props.minCount? this.props.minCount:1

    incrementCount() {
        const value = parseInt(this.state.count) + 1
        if(value <= this.maxCount) {
            this.setState({count: value})
        }
    }

    decrementCount() {
        const value = parseInt(this.state.count) - 1
        if(value >= this.minCount) {
            this.setState({count: value})
        }
    }

    toggleBuyDialog(value) {
        this.setState({buyDialogOpen: value})
    }

    renderInfoRow(label, link, linkPrefix=false, index=false) {
        return (<Typography key={link} component={'span'} variant='subtitle1' className='contact-info' >{label} {index} <a className='contact-link' href={`${linkPrefix? `${linkPrefix}:`:''}${link}`}>{link}</a></Typography>)
    }

    render() {
        return loadTranslation (translate => (
            <div className='form-buy-product'>
                <div className='name'><Typography variant='h5'>{this.props.name}</Typography></div>
                <div className='note'><Typography variant='h6' >{this.props.note}</Typography></div>
                {
                    this.props.selectors
                }
                {/* <div className='price'><Typography variant='h5'>{intToCurrencyString(this.props.price)} ֏</Typography></div> */}
                {/* <CountInput className='product-counter' value={this.state.count} onDecrement={() => this.decrementCount()} onIncrement={() => this.incrementCount()} /> */}
                <Button className='buy-btn' color="secondary" onClick={() => this.toggleBuyDialog(true)}>{loadTranslation((translate) => String(translate({id: 'BUY'})))}</Button>
                <Dialog
                    open={this.state.buyDialogOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.toggleBuyDialog(false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{translate({id: 'PLEASE_CONTACT_US_TO_BUY'})}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {
                            contacts.contactInfo.emails.map((email, index) => this.renderInfoRow(translate({id: 'E_MAIL'}), email, 'mailto', contacts.contactInfo.emails.length > 1? ` ${index+1}`:false))
                        }
                        {
                            contacts.contactInfo.tel.map((tel, index) => this.renderInfoRow(translate({id: 'TEL'}), tel, 'tel', contacts.contactInfo.tel.length > 1? ` ${index+1}`:false))
                        }
                        {
                            contacts.officialGroups.facebook? this.renderInfoRow('Facebook', contacts.officialGroups.facebook):false
                        }
                        {
                            contacts.officialGroups.twitter? this.renderInfoRow('Twitter', contacts.officialGroups.twitter):false
                        }
                        {
                            contacts.officialGroups.instagram? this.renderInfoRow('Instagram', contacts.officialGroups.instagram):false
                        }
                        {
                            contacts.officialGroups.youtube? this.renderInfoRow('YouTube', contacts.officialGroups.youtube):false
                        }
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    
                    </DialogActions>
                </Dialog>
            </div>
        ))
    }
}