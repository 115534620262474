import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem'
import './style.css'

export default class PaginationLink extends React.Component {
    render() {
        const { page, count } = this.props

        return (
            <Pagination
                    className={`pagination-item${this.props.className? ' '+this.props.className:''}`}
                    page={page}
                    count={count}
                    siblingCount={1}
                    size='small'
                    color="primary"
                    renderItem={(item) => (
                        <PaginationItem
                            component={Link}
                            to={`/accessories/${item.page}`}
                            {...item}
                            />
                    )}
                    />
        )
    }
}