import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import SeriesMenu from '../SeriesMenu'
import LinkButton from '../LinkButton'
import LanguageSelector from '../LanguageSelector'
import IconButton from '@material-ui/core/IconButton'
import { Icon } from '@iconify/react'
import menuLeftAlt from '@iconify/icons-gg/menu-left-alt'
import AsideMenu from '../AsideMenu'
import AppLogo from '../AppLogo'
import { loadTranslation } from '../../translation'

import './style.css'

export default class Navbar extends React.Component {
    state = {
        asideMenuOpened: false,
    }

    toggleAsideMenu(value) {
        this.setState({asideMenuOpened: value})
    }

    render() {
        const menuItems = [
            <LinkButton key={1} onClick={() => this.toggleAsideMenu(false)} className="nav-item" href="/">{loadTranslation(translate => translate({id: 'HOME_PAGE'}))}</LinkButton>,
            <SeriesMenu key={2} onClick={() => this.toggleAsideMenu(false)} className="nav-item" label={loadTranslation(translate => translate({id: 'PRODUCTS'}))}/>,
            <LinkButton key={3} onClick={() => this.toggleAsideMenu(false)} className="nav-item" href="/accessories">{loadTranslation(translate => translate({id: 'ACCESSORIES'}))}</LinkButton>,
            <LinkButton key={4} onClick={() => this.toggleAsideMenu(false)} className="nav-item" href="/about">{loadTranslation(translate => translate({id: 'ABOUT_US'}))}</LinkButton>,
            <LinkButton key={5} onClick={() => this.toggleAsideMenu(false)} className="nav-item" href="/contacts">{loadTranslation(translate => translate({id: 'CONTACTS'}))}</LinkButton>,
        ]

        const asideMenuItems = [
            {
                id: 1,
                label: loadTranslation(translate => translate({id: 'HOME_PAGE'})),
                href: '/'
            },
            {
                id: 2,
                label: loadTranslation(translate => translate({id: 'PRODUCTS'})),
                content: loadTranslation(translate => (<List>
                    <ListItem onClick={() => this.toggleAsideMenu(false)}>
                        <LinkButton href="/series/N">{translate({id: 'N_SERIES'})}</LinkButton>
                    </ListItem>
                    <ListItem onClick={() => this.toggleAsideMenu(false)}>
                        <LinkButton href="/series/U">{translate({id: 'U_SERIES'})}</LinkButton>
                    </ListItem>
                    <ListItem onClick={() => this.toggleAsideMenu(false)}>
                        <LinkButton href="/series/M">{translate({id: 'M_SERIES'})}</LinkButton>
                    </ListItem>
                    <ListItem onClick={() => this.toggleAsideMenu(false)}>
                        <LinkButton href="/series/KQI">{translate({id: 'KICK_SCOOTER_SERIES'})}</LinkButton>
                    </ListItem>                     
                    <ListItem onClick={() => this.toggleAsideMenu(false)}>
                        <LinkButton href="/series/EUB">{translate({id: 'EUB_SERIES'})}</LinkButton>
                    </ListItem>      
                </List>))
            },
            {
                id: 3,
                label: loadTranslation(translate => translate({id: 'ACCESSORIES'})),
                href: '/accessories'
            },
            {
                id: 4,
                label: loadTranslation(translate => translate({id: 'ABOUT_US'})),
                href: '/about'
            },
            {
                id: 5,
                label: loadTranslation(translate => translate({id: 'CONTACTS'})),
                href: '/contacts'
            }
        ]
        const blocked = ['/'].includes(this.props.location.pathname)
        return (
            <div className={`navbar-container${blocked? ' blocked': ''}`}>
                <div className="navbar">
                    <AppBar className="app-bar" position="static">
                        <Toolbar className="toolbar">
                            <div className="aside-menu-btn">
                                <IconButton onClick={() => this.toggleAsideMenu(true)}>
                                    <Icon width={20} icon={menuLeftAlt} />
                                </IconButton>
                            </div>
                            <div className="icon-btn">
                                <AppLogo type='default' className="icon" />
                            </div>
                            <div className="bar-items">
                                {
                                    menuItems
                                }
                            </div>
                            <LanguageSelector className="language-select" label={loadTranslation(translate => translate({id: 'LANGUAGE'}))}/>
                        </Toolbar>
                        <AsideMenu items={asideMenuItems} open={this.state.asideMenuOpened} onClose={() => this.toggleAsideMenu(false)}/>
                    </AppBar>     
                </div>
            </div>
        )
    }
}